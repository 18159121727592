const formData = {
  questions: [
    {
      uuid: '',
      id: 0,
      collaborators: [],
      course: {},
      course_semester: {},
      created_by: '',
      created_at: '',
      creator_type: '',
      course_year: {},
      dds_course_semester: null,
      question_creator: '',
      foundational_knowledge: [],
      clinical_content: [],
      systems_areas: [],
      difficulty_level: [],
      key_concepts: [],
      review_material: [],
      question_stem: {
        text: '',
        attachments: []
      },
      answers: [
        {
          id: 0,
          answer_choice: {
            text: '',
            attachments: []
          },
          explanation: {
            text: '',
            attachments: []
          },
          isCorrect: false
        },
        {
          id: 1,
          answer_choice: {
            text: '',
            attachments: []
          },
          explanation: {
            text: '',
            attachments: []
          },
          isCorrect: false
        }
      ]
    }
  ],
  collaborators: [
    {
      value: 1,
      label: 'David Lee'
    },
    {
      value: 2,
      label: 'Yang Chen'
    },
    {
      value: 3,
      label: 'Tiger Woods'
    },
    {
      value: 4,
      label: 'Peter Sellers'
    },
    {
      value: 5,
      label: 'Ayrton Senna'
    },
    {
      value: 6,
      label: 'Ajmal Khan'
    },
    {
      value: 7,
      label: 'Sandeep Chowdry'
    }
  ],
  testletType: [
    { value: 'patient', label: 'Individual Patient' },
    { value: 'community', label: 'Community Health/Research Testlet' }
  ],
  courseYearOptions: [
    { value: 1, label: 'Anatomy & Physiology I' },
    { value: 2, label: 'Anatomy & Physiology II' },
    { value: 3, label: 'Chemistry for Allied Health' },
    { value: 4, label: 'CPR' },
    { value: 5, label: 'Dental Materials' },
    { value: 6, label: 'Ethics' },
    { value: 7, label: 'General & Oral Pathology' },
    { value: 8, label: 'Human Microbiology I' },
    { value: 9, label: 'Human Microbiology II' },
    { value: 10, label: 'Intro. to Psychology' },
    { value: 11, label: 'Intro. to Sociology' },
    { value: 12, label: 'Nutrition & Health' },
    { value: 13, label: 'Oral Anatomy' },
    { value: 14, label: 'Oral Communications' },
    { value: 15, label: 'Oral Embryology & Histology' },
    { value: 16, label: 'Oral Health Education' },
    { value: 17, label: 'Pain Management' },
    { value: 18, label: 'Periodontics' },
    { value: 19, label: 'Pharmacology' },
    { value: 20, label: 'Prev. Dentistry & Public Health' },
    { value: 21, label: 'Principles of DH I' },
    { value: 22, label: 'Principles of DH I Clinic' },
    { value: 23, label: 'Principles of DH II' },
    { value: 24, label: 'Principles of DH II Clinic' },
    { value: 25, label: 'Principles of DH III' },
    { value: 26, label: 'Principles of DH III Clinic' },
    { value: 27, label: 'Principles of DH IV' },
    { value: 28, label: 'Principles of DH IV Clinic' },
    { value: 29, label: 'Radiology' },
    { value: 30, label: 'Writing Workshop I' },
    { value: 31, label: 'Writing Workshop II' }
  ],
  semesterOptions: [{ value: 1, label: 'Fall' }, { value: 2, label: 'Spring' }],
  adminInbdeCourse: [
    { value: 1, label: 'Prep, SP20, D1' },
    { value: 2, label: 'Prep, FA20, D2' },
    { value: 3, label: 'Prep, SP21, D1' },
    { value: 4, label: 'Prep, SP21, D2' },
    { value: 5, label: 'Prep, FA21, D2' },
    { value: 6, label: 'Prep, FA21, D3' },
    { value: 7, label: 'Prep, SP22, D1' },
    { value: 8, label: 'Prep, SP22, D2' },
    { value: 9, label: 'Prep, SP22, D3' },
    { value: 10, label: 'Prep, FA22, D2' },
    { value: 11, label: 'Prep, FA22, D3' },
    { value: 12, label: 'Prep, FA22, D4' }
  ],
  inbdeCourse: [
    { value: 1, label: 'NBDHE Prep Course, FA23' },
    { value: 2, label: 'NBDHE Prep Course, SU24' },
    { value: 3, label: 'NBDHE Prep Course, FA24' },
    { value: 4, label: 'NBDHE Prep Course, SU25' },
    { value: 5, label: 'NBDHE Prep Course, FA25' },
    { value: 6, label: 'NBDHE Prep Course, SU26' },
    { value: 7, label: 'NBDHE Prep Course, FA26' },
    { value: 8, label: 'NBDHE Prep Course, SU27' }
  ],
  diffcultyLevelOptions: [
    { value: 1, label: 'Application' },
    { value: 2, label: 'Recall' },
    { value: 3, label: 'Synthesis' }
  ],
  inbdeYearSemester: [
    { value: 1, label: 'Spring, 2020', semester: 'Spring', year: 2020 },
    { value: 2, label: 'Fall, 2020', semester: 'Fall', year: 2020 },
    { value: 3, label: 'Spring, 2021', semester: 'Spring', year: 2021 },
    { value: 4, label: 'Fall, 2021', semester: 'Fall', year: 2021 },
    { value: 5, label: 'Spring, 2022', semester: 'Spring', year: 2022 },
    { value: 6, label: 'Fall, 2022', semester: 'Fall', year: 2022 },
    { value: 7, label: 'Spring, 2023', semester: 'Spring', year: 2023 },
    { value: 8, label: 'Fall, 2023', semester: 'Fall', year: 2023 },
    { value: 9, label: 'Spring, 2024', semester: 'Spring', year: 2024 }
  ],
  foundationalKnowledgeOptions: [
    { value: 1, label: 'Anatomic Sciences', isDisabled: true, className: 'text-black' },
    { value: 2, label: 'Anatomy' },
    { value: 3, label: 'Head and neck anatomy' },
    { value: 4, label: 'Dental anatomy' },
    { value: 5, label: 'General anatomy' },
    { value: 6, label: 'Root anatomy' },
    { value: 7, label: 'Histology and Embryology' },
    { value: 8, label: 'Physiology' },
    { value: 9, label: 'Other', isDisabled: true, className: 'text-black' },
    { value: 10, label: 'Biochemistry and Nutrition' },
    { value: 11, label: 'Microbiology and Immunology' },
    { value: 12, label: 'Pathology' },
    { value: 13, label: 'Oral Pathology' },
    { value: 14, label: 'Pharmacology' },
    { value: 15, label: 'Not Applicable' }
  ],
  clinicalContentOptions: [
    { value: 0, label: 'Assessing Patient Characteristics', isDisabled: true, className: 'text-black' },
    {
      selectedName: 'History',
      selected: false,
      value: 1,
      label: 'Medical and dental history'
    },
    {
      selectedName: 'Head and neck',
      selected: false,
      value: 2,
      label: 'Head and neck examination'
    },
    {
      selectedName: 'Periodontal evaluation',
      selected: false,
      value: 3,
      label: 'Periodontal evaluation'
    },
    {
      selectedName: 'Oral evaluation',
      selected: false,
      value: 4,
      label: 'Oral evaluation'
    },
    { value: 5, label: 'Obtaining and Interpreting Radiographs', isDisabled: true, className: 'text-black' },
    {
      selectedName: 'Radiophysics & Radiobiology',
      selected: false,
      value: 6,
      label: 'Principles of radiophysics and radiobiology'
    },
    {
      selectedName: 'Radiologic health',
      selected: false,
      value: 7,
      label: 'Principles of radiologic health'
    },
    {
      selectedName: 'Technique',
      selected: false,
      value: 8,
      label: 'Technique'
    },
    {
      selectedName: 'Recognition',
      selected: false,
      value: 9,
      label: 'Recognition of normalities and abnormalities'
    },
    {
      value: 10,
      label: 'Provision of instruction for prevention and management of oral diseases',
      isDisabled: true,
      className: 'text-black'
    },
    {
      selectedName: 'Dental caries',
      selected: false,
      value: 11,
      label: 'Dental caries'
    },
    {
      selectedName: 'Periodontal disease ',
      selected: false,
      value: 12,
      label: 'Periodontal disease'
    },
    {
      selectedName: 'Oral conditions',
      selected: false,
      value: 13,
      label: 'Oral conditions'
    },
    { value: 14, label: 'Dental hygiene treatment strategies', isDisabled: true, className: 'text-black' },
    {
      selectedName: 'Diagnosis',
      selected: false,
      value: 15,
      label: 'Diagnosis'
    },
    {
      selectedName: 'Treatment plan ',
      selected: false,
      value: 16,
      label: 'Treatment plan'
    },
    {
      selectedName: 'Case presentation',
      selected: false,
      value: 17,
      label: 'Case presentation'
    },
    { value: 18, label: 'Prescribed therapy', isDisabled: true, className: 'text-black' },
    {
      selectedName: 'Periodontal debridement',
      selected: false,
      value: 19,
      label: 'Periodontal debridement'
    },
    {
      selectedName: 'Devices',
      selected: false,
      value: 20,
      label: 'Surgical support services'
    },
    {
      selectedName: 'Chemotherapeutic agents',
      selected: false,
      value: 21,
      label: 'Chemotherapeutic agents'
    },
    { value: 22, label: 'Using Preventive Agents', isDisabled: true, className: 'text-black' },
    {
      selectedName: 'Fluorides',
      selected: false,
      value: 23,
      label: 'Fluorides - systemic and topical'
    },
    {
      selectedName: 'Mechanisms of action',
      selected: false,
      value: 24,
      label: 'Mechanisms of action'
    },
    {
      selectedName: 'Toxicology',
      selected: false,
      value: 25,
      label: 'Toxicology'
    },
    {
      selectedName: 'Administration',
      selected: false,
      value: 26,
      label: 'Methods of administration'
    },
    {
      selectedName: 'Fluoridation',
      selected: false,
      value: 27,
      label: 'Self-administered'
    },
    { value: 28, label: 'Pit and fissure sealants', isDisabled: true, className: 'text-black' },
    {
      selectedName: 'Mechanisms of action',
      selected: false,
      value: 29,
      label: 'Mechanisms of action'
    },
    {
      selectedName: 'Techniques for application',
      selected: false,
      value: 30,
      label: 'Techniques for application'
    },
    { value: 31, label: 'Providing Supportive Treatment Services', isDisabled: true, className: 'text-black' },
    {
      selectedName: 'Materials',
      selected: false,
      value: 32,
      label: 'Properties and manipulation of materials'
    },
    {
      selectedName: 'Polishing',
      selected: false,
      value: 33,
      label: 'Polishing natural and restored teeth'
    },
    { selectedName: 'CC-33', value: 34, label: 'CC-33: Perform oral surgical procedures ' },
    {
      selectedName: 'Study casts',
      selected: false,
      value: 35,
      label: 'Making of impressions and preparation of study casts'
    },
    {
      selectedName: 'Other services',
      selected: false,
      value: 36,
      label: 'Other supportive services (e.g., tooth desensitization)'
    },
    { value: 37, label: 'Professional Responsibility', isDisabled: true, className: 'text-black' },
    {
      selectedName: 'Ethical principles',
      selected: false,
      value: 38,
      label: 'Ethical principles, including informed consent'
    },
    { selectedName: 'Compliance', value: 39, label: 'Regulatory compliance' },
    { value: 40, label: 'Practice and Profession', isDisabled: true, className: 'text-black' },
    {
      selectedName: 'Communication',
      selected: false,
      value: 41,
      label: 'Patient and professional communication'
    },
    { value: 42, label: 'Other Clinical Services', isDisabled: true, className: 'text-black' },
    {
      selectedName: 'Planning',
      selected: false,
      value: 43,
      label: 'Planning and Managing Dental Hygiene Care'
    },
    {
      selectedName: 'Infection control',
      selected: false,
      value: 44,
      label: 'Infection control (application)'
    },
    {
      selectedName: 'Emergency',
      selected: false,
      value: 45,
      label: 'Recognition of emergency situations and provision of appropriate care'
    },
    {
      selectedName: 'Patient education',
      selected: false,
      value: 46,
      label: 'Individualized patient education'
    },
    {
      selectedName: 'Individualized instruction',
      selected: false,
      value: 47,
      label: 'Planning of individualized instruction'
    },
    {
      selectedName: 'Control',
      selected: false,
      value: 48,
      label: 'Anxiety and pain control'
    },
    {
      selectedName: 'Compromised patients',
      selected: false,
      value: 49,
      label: 'Recognition and management of compromised patients'
    },
    {
      selectedName: 'Periodontal',
      selected: false,
      value: 50,
      label: 'Performing Periodontal Procedures'
    },
    {
      selectedName: 'Etiology',
      selected: false,
      value: 51,
      label: 'Etiology and pathogenesis of periodontal diseases'
    },
    {
      selectedName: 'Maintenance',
      selected: false,
      value: 52,
      label: 'Reassessment and maintenance (e.g., implant care)'
    },
    {
      selectedName: 'Preventive Agents',
      selected: false,
      value: 53,
      label: 'Other preventive agents'
    },
    { value: 54, label: 'Not Applicable', isDisabled: false }
  ],
  commentsSection: {
    'testlet-information': {
      title: 'Testlet Information Section'
    },
    'patient-information': {
      title: 'Patient Information Section'
    },
    'question-information': {
      id: 0,
      title: 'Question '
    },
    'demographic-information': {
      title: 'Community Information Section'
    },
    all: {
      title: 'Testlet'
    },
    default: {}
  },
  fieldNameAndValues: {
    testlet_title: { fieldName: 'Testlet/Case Study Title', isRequired: true },
    inbdeCourse: { fieldName: 'NBDHE Prep Course ', isRequired: true },
    course: { fieldName: 'NBDHE Course List', isRequired: true },
    course_semester: { fieldName: 'Semester of Course', isRequired: true },
    foundational_knowledge: { fieldName: 'Foundational Knowledge', isRequired: true },
    clinical_content: { fieldName: 'Clinical Content', isRequired: true },
    difficulty_level: { fieldName: 'Level of Difficulty', isRequired: true },
    key_concepts: { fieldName: 'Key Concepts', isRequired: true },
    question_stem: { fieldName: 'Question Stem', isRequired: true },
    answers: { fieldName: 'Answer Choice', isRequired: true },
    answer_choice: { fieldName: 'Answer Choice', isRequired: true },
    explanation: { fieldName: 'Answer Explanation', isRequired: true },
    type: { fieldName: 'Type', isRequired: true }
  },
  fieldsToInclude: [
    'testlet_title',
    'inbdeCourse',
    'course',
    'course_semester',
    'foundational_knowledge',
    'clinical_content',
    'difficulty_level',
    'key_concepts',
    'question_stem',
    'answers',
    'type'
  ]
};

export default formData;
