import { projectName } from '../../../helpers/constants';

export const contentMetaData = {
  content_id: null,
  collaboratorIds: [],
  created_by: null,
  created_on: null,
  deleted_on: null,
  is_deleted: false,
  is_display_testlet: true,
  is_flagged: false,
  is_latest_version: true,
  is_public: false,
  is_published: false,
  is_testlet_cloned: false,
  last_submitted_by: null,
  opened_for_collaboration_by: null,
  published_inbdeCourse: [],
  published_versions: [],
  testlet_type: '1A',
  testlet_uid: '',
  updated_on: null,
  version: 0,
  project: projectName,
  export_data: null
};

export const lmsExportData = {
  content_updated_at: null,
  created_on: null,
  download_url: null,
  path: null
};
