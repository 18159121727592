import React from 'react';
import { Button, Row } from 'reactstrap';
import PropTypes from 'prop-types';

import { isProd } from '../helpers/inbdeUtils';
import { isIterableArray } from '../helpers/utils';
import NavbarTop from '../components/navbar/NavbarTop';
import Footer from '../components/footer/Footer';
import HeaderLogo from '../components/common/HeaderLogo';
import { googleIdTokenParam } from '../helpers/constants';
import DDSIcon from '../assets/img/illustrations/dds_project_icon.svg';
import DHIcon from '../assets/img/illustrations/dh_project_icon.svg';

export const projectConfigs = [
  {
    projectId: 'inbde',
    displayName: 'DDS',
    fullName: 'Integrated National Board Dental Exam',
    qaUrl: 'https://qa.inbde.dental.nyu.edu',
    prodUrl: 'https://inbde.dental.nyu.edu',
    icon: DDSIcon
  },
  {
    projectId: 'nbdhe',
    displayName: 'DH',
    fullName: 'National Board Dental Hygiene Examination',
    qaUrl: 'https://nyu-dental-hygiene-dev.web.app',
    prodUrl: 'https://nbdhe.dental.nyu.edu',
    icon: DHIcon
  }
];

const ProjectsListPage = ({ googleIdToken, projects, path }) => {
  const handleProjectClick = projectConfig => {
    const isProdEnv = isProd();
    const projectUrl = isProdEnv ? projectConfig.prodUrl : projectConfig.qaUrl;
    const navigationUrl = new URL(projectUrl);
    navigationUrl.pathname = path;
    navigationUrl.searchParams.append(googleIdTokenParam, `${googleIdToken}`);
    window.location.replace(navigationUrl);
  };

  const userProjects = projectConfigs.filter(config => projects.includes(config.projectId));
  return (
    <div className="container-fluid pr-xl-5 pl-xl-5">
      <div className="content">
        <NavbarTop />
        <HeaderLogo />
        <div className="text-center py-4">
          {isIterableArray(userProjects) ? (
            <>
              <h1 className="mb-8">Welcome Back</h1>
              <h4 className="mb-2">In what program do you teach?</h4>
              <Row noGutters className="justify-content-center card-columns">
                {userProjects.map(config => (
                  <div key={config.projectId} className="p-2">
                    <Button
                      onClick={() => handleProjectClick(config)}
                      className="p-0 d-flex align-items-center bg-100 project-card"
                    >
                      <div>
                        <img src={config.icon} alt="" />
                      </div>
                      <div className="fs-2 p-2">
                        <h5 className="text-sans-serif font-weight-bold mb-0 pl-2">{config.displayName}</h5>
                      </div>
                    </Button>
                  </div>
                ))}
              </Row>
            </>
          ) : (
            <h3 className="text-success">You are not enrolled in any program</h3>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
};

ProjectsListPage.defaultProps = {
  projects: []
};

ProjectsListPage.propTypes = {
  googleIdToken: PropTypes.string,
  path: PropTypes.string,
  projects: PropTypes.array.isRequired
};

export default ProjectsListPage;
