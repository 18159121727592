import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { CardBody, Row } from 'reactstrap';
import { contentTypes } from '../../../data/content/contentTypes';

const NOT_SPECIFIED = 'Not Specified';
export const ContentSummaryCardBody = ({
  collaborators,
  contentCreator,
  contentType,
  courseInformation,
  inbdePrepCourse,
  mockExams,
  version
}) => {
  const contentTypeTitle = contentTypes[contentType] ? contentTypes[contentType].name : 'Content';
  return (
    <Fragment>
      <CardBody className="pt-2 pb-2 pl-1">
        <Row className="ml-0">
          <div className="col-12 col-md-6 col-xl-5 pt-1 pb-1">
            <Row>
              <div className="col-12 font-weight-semi-bold">Course and Semester of course:</div>
              <div className="col-12 text-truncate">{courseInformation || NOT_SPECIFIED}</div>
            </Row>
          </div>
          <div className="col-12 col-md-6 col-xl-3 pt-1 pb-1">
            <Row>
              <div className="col-12 font-weight-semi-bold">{contentTypeTitle} Originator:</div>
              <div className="col-12 text-truncate">{contentCreator || NOT_SPECIFIED}</div>
            </Row>
          </div>
          <div className="col-12 col-md-6 col-xl-4 pt-1 pb-1">
            <Row>
              <div className="col-12 font-weight-semi-bold">Collaborators:</div>
              <div className="col-12 text-truncate">{collaborators || NOT_SPECIFIED}</div>
            </Row>
          </div>
          <div className="col-12 col-md-5 pt-1 pb-1">
            <Row>
              <div className="col-12 font-weight-semi-bold">NBDHE Prep Course:</div>
              <div className="col-12 text-truncate">{inbdePrepCourse || NOT_SPECIFIED}</div>
            </Row>
          </div>
          <div className="col-12 col-md-6 col-xl-3 pt-1 pb-1">
            <Row>
              <div className="col-12 font-weight-semi-bold">Exam:</div>
              <div className="col-12 text-truncate">{mockExams || NOT_SPECIFIED}</div>
            </Row>
          </div>
          <div className="col-12 col-md-6 col-xl-4 pt-1 pb-1">
            <Row>
              <div className="col-12 font-weight-semi-bold">Version:</div>
              <div className="col-12 text-truncate">{version || 0}</div>
            </Row>
          </div>
        </Row>
      </CardBody>
    </Fragment>
  );
};

ContentSummaryCardBody.propTypes = {
  collaborators: PropTypes.string,
  contentCreator: PropTypes.string,
  contentType: PropTypes.string.isRequired,
  courseInformation: PropTypes.string,
  inbdePrepCourse: PropTypes.string,
  mockExams: PropTypes.string,
  version: PropTypes.number
};
