/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import * as firebase from 'firebase/app';
import 'firebase/auth';

import SpinnerModal from '../components/common/SpinnerModal';
import Login from '../components/auth/split/Login';
import WhiteListService from '../services/WhiteList/WhiteListService';
import { UserContext } from '../Contexts';
import AuthenticationService from '../services/Authentication/AuthenticationService';
import UserService from '../services/User/UserService';
import FeatureService from '../services/Feature/FeatureService';
import ProjectsListPage from './ProjectsListPage';
import { isIterableArray } from '../helpers/utils';
import getQueryParamValue from '../helpers/getRequestQueryParams';
import { logoutParam } from '../helpers/constants';

class LandingPage extends Component {
  _isMounted = false;
  whiteListService = new WhiteListService();
  authenticationService = new AuthenticationService();
  unsubscribe;
  userService = new UserService();
  featureService = new FeatureService();

  constructor(props) {
    super(props);

    this.state = {
      isUserAuthenticated: false,
      isLoading: false,
      userAccess: 'faculty',
      user: null,
      email: '',
      admins: [],
      toggles: {},
      path: '',
      googleIdToken: '',
      projects: []
    };
  }

  componentDidMount() {
    const { location } = this.props;
    const path = location.pathname || '';
    const search = location.search;
    const logoutValue = getQueryParamValue(logoutParam, search);

    this._isMounted = true;
    this.setState({ isLoading: true, path });

    this.unsubscribe = firebase.auth().onAuthStateChanged(firebaseUser => {
      if (firebaseUser) {
        // User is signed in to firebase.
        this.userService.isUserLoggedIn(firebaseUser.uid, false, data => {
          const googleIdToken = data ? data.google_id_token : '';
          if (logoutValue) {
            this.signOutUser(googleIdToken);
          }

          if (data && data.is_logged_in) {
            const { full_name: displayName, image_url: photoURL } = data;
            const { email, uid, phoneNumber } = firebaseUser;
            this.applicationContext({ email, uid, phoneNumber, displayName, photoURL, googleIdToken });
          } else {
            this._isMounted &&
              this.setState({
                isUserAuthenticated: false,
                isLoading: false,
                userAccess: 'faculty',
                user: null,
                email: ''
              });
          }
        });
      } else {
        // No user is signed in.
        this.userService.isUserLoggedIn(null, true, () => {});
        this._isMounted &&
          this.setState({
            isUserAuthenticated: false,
            isLoading: false,
            userAccess: 'faculty',
            user: null,
            email: ''
          });
      }
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
    this._isMounted = false;
  }

  applicationContext(loggedInUser) {
    const { email, googleIdToken } = loggedInUser;
    this.whiteListService.getWhiteListEntry(email, false, async data => {
      if (data) {
        const { access_type, projects } = data;
        if (isIterableArray(projects)) {
          let user = loggedInUser;
          user.access_type = access_type;

          const admins = await this.userService.getListOfAdmins();
          this.featureService.getListOfToggles(false, toggleList => {
            this._isMounted &&
              this.setState({
                userAccess: access_type,
                isLoading: false,
                isUserAuthenticated: true,
                email,
                user,
                admins,
                toggles: toggleList ? toggleList : {},
                googleIdToken,
                projects
              });
          });

          return;
        }
      }

      // comment out the two lines below to show user logged in app but without whitelist
      this._isMounted && this.setState({ isLoading: true });
    });
  }

  async signOutUser(googleIdToken) {
    const { user, email } = this.state;

    user && this.userService.isUserLoggedIn(user.uid, true, () => {});
    email && this.whiteListService.getWhiteListEntry(email, true, () => {});
    await this.featureService.getListOfToggles(true, () => {});
    await this.authenticationService.signOutOfFirebase(googleIdToken);
    this._isMounted &&
      this.setState(
        {
          isUserAuthenticated: false,
          isLoading: false,
          userAccess: 'faculty',
          user: null,
          email: ''
        },
        () => {
          window.location.reload();
        }
      );
  }

  render() {
    const { isUserAuthenticated, isLoading, user, admins, toggles, path, googleIdToken, projects } = this.state;

    return (
      <>
        {isLoading && <SpinnerModal />}
        {isUserAuthenticated ? (
          <UserContext.Provider value={{ user, admins, toggles, path }}>
            <ProjectsListPage googleIdToken={googleIdToken} path={path} projects={projects} />
          </UserContext.Provider>
        ) : (
          <>
            <Redirect to="/" />
            <Login />
          </>
        )}
      </>
    );
  }
}

export default LandingPage;
