import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { toast } from 'react-toastify';

import SpinnerModal from '../common/SpinnerModal';
import AuthenticationService from '../../services/Authentication/AuthenticationService';
import User from '../../data/models/User/User';
import UserService from '../../services/User/UserService';
import FirebaseLoggingService from '../../services/Firebase/FirebaseLoggingService';
import { getCurrentTimeStamp } from '../../helpers/inbdeUtils';
import OAuthGoogleLogin from './OAuthGoogleLogin';
import { isIterableArray } from '../../helpers/utils';

const provider = new firebase.auth.GoogleAuthProvider();

class GoogleSignIn extends Component {
  _isMounted = false;
  _isListened = false;
  authenticationService = new AuthenticationService();
  userService = new UserService();

  constructor(props) {
    super(props);

    this.state = { isLoading: false };

    this.authenticateUser = this.authenticateUser.bind(this);
    this.handleGoogleLogin = this.handleGoogleLogin.bind(this);
    this.responseGoogleSuccess = this.responseGoogleSuccess.bind(this);
    this.responseGoogleFail = this.responseGoogleFail.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async authenticateUser(response) {
    const { additionalUserInfo } = response;
    let errorMessage = 'User does not have permission to access';
    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        if (!this._isListened) {
          this._isListened = true;

          const email = user.email ? user.email : additionalUserInfo.profile.email;
          const whitelistEntry = await this.userService.isUserInWhitelist(email);

          if (whitelistEntry) {
            const { projects } = whitelistEntry;
            if (isIterableArray(projects)) {
              const isSuccess = await this.handleUserLogin(response, user, whitelistEntry);

              if (isSuccess) {
                this.props.setEmail(email);
                return;
              } else {
                errorMessage = 'Unable to Sign In at the moment';
              }
            }
          }

          await firebase.auth().signOut();

          this._isMounted && this.setState({ isLoading: false });
          toast.error(errorMessage);
          // window.location.reload();
        }
      }
    });
  }

  async handleGoogleLogin() {
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(result => {
        /** @type {firebase.auth.OAuthCredential} */
        this.responseGoogleSuccess(result);
      })
      .catch(error => {
        this.responseGoogleFail(error);
      });
  }

  async handleUserLogin(googleUserData, firebaseUserData, whitelistEntry) {
    const { uid: user_id, email, displayName } = firebaseUserData;
    const currentTimeStamp = getCurrentTimeStamp();

    if (!this.loggingService) {
      this.loggingService = new FirebaseLoggingService({
        userId: user_id,
        userName: displayName,
        email
      });
    }

    const user = await this.userService.getUserDetails(user_id);
    const user_exists = user ? user.exists : false;
    const { access_type: access, projects } = whitelistEntry;

    if (!user_exists) {
      const isSuccess = await this.signUpUser(googleUserData, user_id, currentTimeStamp, access, projects);
      return isSuccess;
    } else {
      const { credential } = googleUserData;
      const { idToken: googleIdToken } = credential;
      const userDevice = navigator && navigator.userAgent ? navigator.userAgent : '';
      const data = {
        last_login: currentTimeStamp,
        updated_on: currentTimeStamp,
        is_logged_in: true,
        deleted_on: null,
        deactivated_on: null,
        is_active: 'active',
        access_type: access,
        projects,
        user_device: userDevice,
        google_id_token: googleIdToken
      };

      const isSuccess = await this.userService.updateUserLogin(firebaseUserData, data);
      if (isSuccess) {
        this.loggingService && this.loggingService.userHasLoggedIn(user_id);
        this._isMounted && this.setState({ isLoading: false });
      }

      return isSuccess;
    }
  }

  responseGoogleFail(error) {
    this.loggingService && this.loggingService.logInbdeException(error, 'Google Sign In error');
    const errorMessage = error.message ? `Login Error: ${error.message}` : 'Unable to sign in';
    toast.error(errorMessage);
  }

  responseGoogleSuccess(response) {
    this._isMounted = true;
    this._isListened = false;
    this._isMounted && this.setState({ isLoading: true });
    this.authenticateUser(response);
  }

  async signUpUser(googleUserData, user_id, currentTimeStamp, access, projects) {
    const { additionalUserInfo, credential: googleTokenObject } = googleUserData;
    const { profile: googleProfile } = additionalUserInfo;
    const userDevice = navigator && navigator.userAgent ? navigator.userAgent : '';
    const { idToken } = googleTokenObject;

    let userDetails = (
      <User
        id={user_id}
        full_name={googleProfile.name}
        given_name={googleProfile.given_name}
        email={googleProfile.email}
        image_url={googleProfile.picture}
        access_type={access}
        is_active={'active'}
        created_on={currentTimeStamp}
        deleted_on={null}
        deactivated_on={null}
        last_login={currentTimeStamp}
        updated_on={currentTimeStamp}
        isLoggedIn={true}
        user_device={userDevice}
        google_id_token={idToken}
        projects={projects}
      />
    );

    const isSuccess = await this.userService.createUser(userDetails);
    if (isSuccess) {
      this.loggingService && this.loggingService.userHasSignedUp(user_id);
      this._isMounted && this.setState({ isLoading: false });
    }

    return isSuccess;
  }

  render() {
    const { isLoading } = this.state;

    return (
      <div>
        {isLoading && <SpinnerModal />}
        <OAuthGoogleLogin handleLogin={this.handleGoogleLogin} />
      </div>
    );
  }
}

GoogleSignIn.propTypes = {
  setEmail: PropTypes.func.isRequired
};

export default GoogleSignIn;
