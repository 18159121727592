import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import { contentTypes } from '../../../data/content/content';
import { ViewQuestionDetailCard } from './stand-alone-question/stand-alone-question';
import { BaseContentActionsWrapper } from '../action/BaseContentActionsWrapper';

export const ViewContentDetailCard = ({
  contentData,
  contentId,
  fetchContentData,
  handleContentAction,
  isCollaboration,
  isOpen,
  isPartialData,
  isReview,
  isUserAdmin,
  setDetailsForContent,
  toggleView,
  userId: uid
}) => {
  const [contentDetails, setContentDetails] = useState(contentData);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const mountedRef = useRef(null);
  const { content_type, created_by } = contentData;

  const handleAction = async (action, data) => {
    setIsLoading(true);

    const payload = data || contentData;
    await handleContentAction(contentId, action, payload);

    mountedRef.current && setIsLoading(false);
  };

  const deleteContent = () => {
    handleAction('delete');
  };

  const submitContent = async () => {
    handleAction('submit');
  };

  const updateContentByAdmin = (adminAction, data) => {
    handleAction(adminAction, data);
  };

  const updateContentByFaculty = facultyAction => {
    handleAction(facultyAction);
  };

  useEffect(() => {
    mountedRef.current = true;

    async function fetchContentDetails() {
      const contentDetails = await fetchContentData(contentId);
      if (contentDetails === null) {
        mountedRef.current && setShowErrorMessage(true);
      } else {
        mountedRef.current && setContentDetails(contentDetails);
        // Update local state of parent page by saving content details to prevent fetching details again
        setDetailsForContent(contentId, contentDetails);
      }
    }

    if (isPartialData) {
      fetchContentDetails();
    } else {
      mountedRef.current && setContentDetails(contentData);
    }

    return () => {
      mountedRef.current = false;
    };
  }, [contentData, contentId, fetchContentData, isPartialData, setDetailsForContent]);

  let content;
  if (content_type === contentTypes.question.type) {
    content = (
      <ViewQuestionDetailCard
        isCollaboration={isCollaboration}
        questionData={contentDetails}
        questionId={contentId}
        showErrorMessage={showErrorMessage}
      />
    );
  } else content = <div />;

  return (
    <Modal isOpen={isOpen} toggle={toggleView} className="modal-lg modal-xl">
      {content}
      <BaseContentActionsWrapper
        contentData={contentData}
        contentId={contentId}
        deleteContent={deleteContent}
        handleCancel={toggleView}
        isFormView={false}
        isLoading={isLoading}
        isReview={isReview}
        isUserAdmin={isUserAdmin}
        isUserContentCreator={created_by === uid}
        persistContent={submitContent}
        updateContentByAdmin={updateContentByAdmin}
        updateContentByFaculty={updateContentByFaculty}
        userId={uid}
      />
    </Modal>
  );
};

ViewContentDetailCard.propTypes = {
  ajaxSuccess: PropTypes.func,
  contentData: PropTypes.object.isRequired,
  contentId: PropTypes.string.isRequired,
  fetchContentData: PropTypes.func,
  handleContentAction: PropTypes.func,
  isCollaboration: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  isPartialData: PropTypes.bool,
  isReview: PropTypes.bool,
  isUserAdmin: PropTypes.bool,
  pageTitle: PropTypes.string,
  setDetailsForContent: PropTypes.func,
  toggleView: PropTypes.func.isRequired,
  userId: PropTypes.string
};

ViewContentDetailCard.defaultProps = {
  ajaxSuccess: () => {},
  fetchContentData: () => {},
  handleContentAction: () => {},
  isCollaboration: false,
  isPartialData: false,
  isReview: false,
  isUserAdmin: false,
  pageTitle: '',
  setDetailsForContent: () => {},
  userId: ''
};
