import React from 'react';
import { googleLogout } from '@react-oauth/google';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { toast } from 'react-toastify';

import Avatar from '../common/Avatar';
import genericIcon from '../../assets/img/icons/generic-user-icon.png';
import AuthenticationService from '../../services/Authentication/AuthenticationService';
import FirebaseLoggingService from '../../services/Firebase/FirebaseLoggingService';
import { UserContext } from '../../Contexts';

class ProfileDropdown extends React.Component {
  _isMounted = false;
  authenticationService = new AuthenticationService();
  loggingService = new FirebaseLoggingService();

  constructor(props) {
    super(props);

    this.state = { profileIcon: genericIcon, email: '', name: '', accessType: null, userId: null, gid: '' };

    this.logoutFail = this.logoutFail.bind(this);
    this.logoutSuccess = this.logoutSuccess.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    const { user } = this.context;
    this.getUserDetails(user);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getUserDetails(user) {
    const { displayName: name, photoURL: profileIcon, email, access_type, googleIdToken: gid, uid: userId } = user;
    let userAccess = access_type === 'admin' ? 'Admin' : null;

    if (this._isMounted) {
      this.setState({
        profileIcon,
        email,
        name,
        accessType: userAccess,
        userId,
        gid
      });
    }
  }

  logoutFail() {
    toast.error('Could not signout at the moment!');
  }

  async logoutSuccess() {
    googleLogout();
    const { userId, gid } = this.state;

    const isLoggedOut = await this.authenticationService.signOutOfFirebase(gid);
    if (!isLoggedOut) {
      toast.error('Could not signout at the moment!');
    } else {
      this.loggingService.userHasLoggedOut(userId);
      window.location.reload();
    }
  }

  render() {
    const { email, name, profileIcon, accessType } = this.state;

    return (
      <>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav className="pr-0 d-table">
            <span className="d-none d-md-table-cell align-middle pr-1 pr-lg-2">{name}</span>
            <Avatar src={profileIcon} />
          </DropdownToggle>
          <DropdownMenu right className="dropdown-menu-card">
            <div className="bg-white rounded-soft py-2">
              <DropdownItem className="cursor-default">{email}</DropdownItem>
              {accessType && (
                <DropdownItem className="cursor-default">
                  <strong>{accessType}</strong>
                </DropdownItem>
              )}
              <DropdownItem divider />
              <DropdownItem>
                <div onClick={this.logoutSuccess}>Logout</div>
              </DropdownItem>
            </div>
          </DropdownMenu>
        </UncontrolledDropdown>
      </>
    );
  }
}

ProfileDropdown.contextType = UserContext;

export default ProfileDropdown;
