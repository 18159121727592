import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';

import Layout from './layouts/Layout';
import ErrorBoundary from './pages/ErrorBoundary';
import application_properties from './applicationProperties';

const App = () => {
  return (
    <GoogleOAuthProvider clientId={application_properties.client_id}>
      <Router basename={process.env.PUBLIC_URL}>
        <ErrorBoundary>
          <Layout />
        </ErrorBoundary>
      </Router>
    </GoogleOAuthProvider>
  );
};

export default App;
