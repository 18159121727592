import types from '../data/testlet/type';
import { prodCurrentUrl, qaCurrentUrl } from '../routes.js';
import { isEmailValid, isIterableArray } from './utils';
import { v4 as uuidv4 } from 'uuid';
import adminTypes from '../data/testlet/adminTypes';
import { TestletTypes as ReviewTestletTypes } from '../data/testlet/review_testlet_types';
import { TestletTypes as AllTestletTypes } from '../data/testlet/all_testlet_types';
import { toast } from 'react-toastify';
import { isEmpty, isEqual } from 'lodash';
import { contentTypes } from '../data/content/contentTypes';

export const isProd = () => {
  const env = process.env['REACT_APP_ENV'];
  return env === 'production';
};

export const getBaseUrl = () => {
  return isProd() ? prodCurrentUrl : qaCurrentUrl;
};

export const isLocalHost = () => {
  return window.location.hostname.includes('localhost');
};

export function isFieldEmpty(key, form) {
  const type = typeof form[key];
  if (type === 'undefined' || form[key] === null) {
    return true;
  }

  if (type === 'string' && form[key] === '') {
    return true;
  }

  if (type === 'object') {
    if (Array.isArray(form[key]) && form[key].length === 0) {
      return true;
    }

    if (Array.isArray(form[key]) && form[key].length === 1) {
      const childElement = form[key][0];
      return typeof childElement === 'object' && isEmpty(childElement);
    }

    if (key === 'question_stem') {
      return isFieldEmpty('text', form[key]);
    }

    return isEmpty(form[key]);
  }

  return false;
}

export const isObjectNullOrEmpty = objectToCheck => {
  return objectToCheck === null || objectToCheck === undefined || isEmpty(objectToCheck);
};

export const isStringNullOrEmpty = stringToCheck => {
  if (typeof stringToCheck !== 'string') {
    return true;
  }

  if (stringToCheck.trim() === '') {
    return true;
  }

  return false;
};

// TO-DO: Add support for camel case as well
export function getBeautifiedValue(value) {
  const separatedField = value ? value.split('_') : [];
  const beautifiedField = separatedField.map(item => {
    return (item = item.charAt(0).toUpperCase() + item.slice(1));
  });
  return beautifiedField.join(' ');
}

export function getCurrentTimeStamp() {
  return Date.now();
}

export function getCollaboratorName(collaboratorModel) {
  if (collaboratorModel) {
    let collaboratorName = collaboratorModel.label;
    const collaboratorEmail = collaboratorModel.email;

    if (collaboratorEmail) {
      const collaboratorNameArray = collaboratorName.split(' ');
      const lastIndex = collaboratorNameArray.length - 1;

      const isHaveEmailInName = collaboratorNameArray[lastIndex].indexOf(collaboratorEmail) !== -1;
      return isHaveEmailInName ? collaboratorNameArray.slice(0, lastIndex).join(' ') : collaboratorName;
    }

    return collaboratorName;
  }

  return '';
}

export const getDateAndTime = unix_timestamp => {
  if (unix_timestamp === null) {
    return '';
  }

  const date = new Date(unix_timestamp);
  const formattedDate = date.toLocaleTimeString() + ' on ' + date.toLocaleDateString();

  return formattedDate;
};

export const getCollabStatusBadge = () => {
  const className = 'nyu';
  const text = 'In Collaboration';
  const tooltip = 'You are a collaborator for this testlet';

  return { className, text, tooltip };
};

export const getPublishStatusBadge = () => {
  const className = 'success';
  const text = 'Published';
  const tooltip = 'This testlet has been released to students in a Prep Course';

  return { className, text, tooltip };
};

export const getUnpublishStatusBadge = () => {
  const className = 'warning';
  const text = 'Unpublished';
  const tooltip = 'This testlet has not yet been released to students in a Prep Course';

  return { className, text, tooltip };
};

export const getAllTestletTypes = () => {
  return [1, '1', '1A', '1B', '2A', '2B', '2C', '3', '4', '5'];
};

export const getAdminStatusDetails = testletType => {
  let className = '';
  let text = '';
  let tooltip = '';

  adminTypes.forEach(type => {
    if (type.title === testletType) {
      className = type.class;
      text = 'Type: ' + type.title;
      tooltip = type.tooltip;
      return;
    }
  });
  return { className, text, tooltip };
};

export function getStatusDetails(title) {
  let className = '';
  let text = '';
  let tooltip = '';

  types.forEach(type => {
    if (type.title === title) {
      className = type.class;
      text = type.text;
      tooltip = type.tooltip;
      return;
    }
  });
  return { className, text, tooltip };
}

export function getMessageToDelete(array1, array2) {
  const array1Len = array1.length;
  const array2Len = array2.length;
  if (array1Len < array2Len && array2Len - array1Len === 1) {
    let valueToRemove;

    for (let i = 0; i < array2Len; i += 1) {
      for (let j = 0; j < array1Len; j += 1) {
        if (array2[i].id === array1[j].id) {
          valueToRemove = null;
          break;
        } else valueToRemove = array2[i];
      }
      if (valueToRemove) break;
    }

    return valueToRemove;
  }

  return null;
}

export function getUpdatedMessage(array1, array2) {
  let updatedMessage = null;

  for (let i = 0; i < array1.length; i += 1) {
    if (!isEqual(array1[i], array2[i])) {
      updatedMessage = array1[i];
      break;
    }
  }

  return updatedMessage;
}

export const formatDate = date => {
  const options = {
    weekday: 'short',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  };

  const now = date
    .toLocaleString('en-US', options)
    .split(',')
    .map(item => item.trim());

  return {
    day: now[0],
    hour: now[3],
    date: now[1] + ', ' + now[2]
  };
};

export const isObjectEqual = (object1, object2) => {
  if (typeof object1 !== 'object') return false;

  if (typeof object1 !== typeof object2) return false;

  // check and compare in case of arrays
  if (Array.isArray(object1) && !Array.isArray(object2)) return false;
  if (Array.isArray(object1) && Array.isArray(object2)) {
    const isArrayEqual = isArrayStrictlyEqual(object1, object2);

    if (!isArrayEqual) return false;
  }

  // a JS object
  const obj1Keys = Object.keys(object1).length;
  const obj2Keys = Object.keys(object2).length;

  if (obj1Keys !== obj2Keys) {
    return false;
  }

  for (let key in object1) {
    const type1 = typeof object1[key];
    const type2 = typeof object2[key];

    if (!object2[key]) {
      return false;
    }

    if (type1 !== type2) {
      return false;
    }

    if (type1 === 'object') {
      const isChildObjectEqual = isObjectEqual(object1[key], object2[key]);
      if (!isChildObjectEqual) return false;
    } else {
      if (object1[key] !== object2[key]) return false;
    }
  }

  return true;
};

export const isArrayStrictlyEqual = (array1, array2) => {
  if (array1.length !== array2.length) {
    return false;
  }

  for (let index = 0; index < array1.length; index += 1) {
    if (typeof array1[index] !== typeof array2[index]) return false;
    if (typeof array1[index] === 'object') {
      const isValueObjectEqual = isObjectEqual(array1[index], array2[index]);
      if (!isValueObjectEqual) return false;
    } else {
      if (array1[index] !== array2[index]) return false;
    }
  }

  return true;
};

export const isEmailOfNYU = email => {
  if (isEmailValid(email)) {
    if (isLocalHost()) {
      return true;
    }

    const nyuEmailDomain = '@nyu.edu';
    if (
      email.indexOf(nyuEmailDomain, email.length - nyuEmailDomain.length) !== -1 &&
      email.substring(email.lastIndexOf(nyuEmailDomain), email.length) === nyuEmailDomain
    ) {
      return true;
    }
  }

  return false;
};

export const getCommentsFromUpdates = (testletId, section, updateValues, userDetails) => {
  const comments = [];

  for (let key in updateValues) {
    comments.push({
      id: uuidv4(),
      testlet_id: testletId,
      created_at: getCurrentTimeStamp(),
      created_by_id: 'system',
      created_by_name: 'Change made',
      created_by_avatar: '',
      deleted_at: null,
      comment: {
        ...updateValues[key],
        made_by_name: userDetails.displayName.split(' ')[0],
        made_by_id: userDetails.uid
      },
      section: section,
      emojis: {},
      seen_list: [],
      isUpdate: true
    });
  }

  return comments;
};

export const getCommentForChange = (testletId, changeValues, userDetails) => {
  const section = 'testlet-changes';
  return {
    id: uuidv4(),
    testlet_id: testletId,
    created_at: getCurrentTimeStamp(),
    created_by_id: 'system',
    created_by_name: 'Change Requested',
    created_by_avatar: '',
    deleted_at: null,
    comment: {
      ...changeValues,
      made_by_name: userDetails.displayName.split(' ')[0],
      made_by_id: userDetails.uid
    },
    section,
    emojis: {},
    seen_list: [],
    isChange: true
  };
};

export const convertToCSV = users => {
  let content = '';

  users.forEach(user => {
    content += user.email + ', ' + user.access_type + '\n';
  });
  content = content.trim();

  const file = new Blob([content], { type: 'text/csv' });
  return file;
};

/* To-DO: Make the function generic and refactor */
export const convertFilterOptionsToAlgoliaAttributes = (filterOptions, newFilterQuery, filterQuery) => {
  // all filters
  const filterAttributes = filterOptions.map(filter => {
    // one filter group / dropdown
    const filterOptionQuery = [];
    const { filters, filterValue } = filter;
    const isPublishedArray = [];

    filters.forEach(label => {
      // one filter option
      if (isIterableArray(filterValue)) {
        if (isIterableArray(label)) {
          filterValue.forEach(
            value => value === 'testlet_type' && label.forEach(item => filterOptionQuery.push(value + ':' + item))
          );
        } else {
          filterValue.forEach(value => {
            if (value === 'is_published') isPublishedArray.push(label);
            else if (value !== 'testlet_type') filterOptionQuery.push(value + ':' + label);
          });
        }
      } else filterOptionQuery.push(filterValue + ':' + label);
    });

    if (isPublishedArray.length === 1) {
      const label = isPublishedArray[0];
      newFilterQuery = filterQuery + ' AND is_published=' + (label ? 1 : 0);
    } else {
      const indexLocation = newFilterQuery.indexOf('is_published');
      const indexLength = 'AND is_published='.length + 1;

      if (indexLocation !== -1) {
        const existingPublishedFilter = newFilterQuery.substring(indexLocation, indexLocation + indexLength);
        newFilterQuery = newFilterQuery.replace(existingPublishedFilter, '');
      }
    }

    return filterOptionQuery;
  });

  return { filterAttributes, updatedNewFilterQuery: newFilterQuery };
};

export const isUserAccessOfTypeAdmin = accessType => {
  if (typeof accessType === 'string') {
    return accessType === 'admin';
  }

  return false;
};

export const parseFilterNames = filterOptions => {
  let year = '';
  const filterNamesArray = [];
  let filterName = '';

  if (isIterableArray(filterOptions)) {
    for (let i = 0; i < filterOptions.length; i += 1) {
      if (filterOptions[i]['filterValue'] === 'created_on') {
        year = filterOptions[i]['filters'].join(', ');
      } else {
        filterNamesArray.push(filterOptions[i]['label']);
      }
    }

    if (year) {
      filterName = filterNamesArray.join(', ') + ' - ' + year;
    } else {
      filterName = filterNamesArray.join(', ');
    }
  }

  return filterName;
};

export const getReviewTestletType = (type, isFlagged) => {
  if (isFlagged) {
    return ReviewTestletTypes.FLAGGED.key;
  }

  for (let testletType in ReviewTestletTypes) {
    const potentialTypes = ReviewTestletTypes[testletType].types;
    if (potentialTypes.includes(type)) {
      return testletType;
    }
  }

  return null;
};

export const getTestletTypeForAllAdminPage = (isLatest, isDeleted) => {
  if (!isDeleted) {
    return AllTestletTypes.ALL.key;
  }

  if (isLatest) {
    return AllTestletTypes.ARCHIVES.key;
  }

  return AllTestletTypes.REJECTED_COLLABS.key;
};

export const isValueInArrayOfObjects = (array, propertyName, value) => {
  if (!isIterableArray(array)) {
    return false;
  }

  for (let i = 0; i < array.length; i += 1) {
    const object = array[i];
    if (typeof object !== 'object') {
      return false;
    }

    if (typeof object[propertyName] === 'undefined') {
      return false;
    }

    if (object[propertyName] === value) {
      return true;
    }
  }

  return false;
};

export const getTestletCreationDays = creationTimestamp => {
  const currentTimeStamp = getCurrentTimeStamp();
  const millisecondsPerDay = 24 * 60 * 60 * 1000;

  return Math.floor((currentTimeStamp - creationTimestamp) / millisecondsPerDay);
};

export const getDDSCourseId = courseNumber => {
  // all characters except words and digits
  const regExp = /[^\w\d]/g;
  return courseNumber.replace(regExp, '');
};

export const getDDSCourseText = courseId => {
  if (courseId.length > 8) {
    const courseText = courseId.slice(0, 5) + '-' + courseId.slice(5, 7) + '.' + courseId.slice(7);
    return courseText;
  }
  return null;
};

export const isFeatureActive = (featureToggleName, toggles, toastText, contextId) => {
  const featureValue = toggles[featureToggleName];
  if (typeof featureValue === 'boolean' && !featureValue) {
    if (toastText) {
      const toastMessage = 'Ability to {customString} has been disabled at the moment'.replace(
        '{customString}',
        toastText
      );
      toast.error(toastMessage);
    }
    return false;
  } else if (typeof featureValue === 'object' && featureValue !== null) {
    const { is_enabled, strategy, context_ids } = featureValue;
    if (typeof is_enabled === 'boolean' && !is_enabled) {
      return false;
    }

    if (strategy === 'specific') {
      if (isIterableArray(context_ids) && contextId) {
        return context_ids.includes(contextId);
      }
      return false;
    }
  }
  return true;
};

export const getContentName = (contentData, isFileName) => {
  let name = '';

  try {
    if (isObjectNullOrEmpty(contentData)) {
      throw new Error();
    }

    const { content_type, question, testlet_information } = contentData;

    if (content_type === contentTypes.question.type) {
      name = 'Stand-Alone Question';
      const { question_stem } = question;
      const { text } = question_stem;
      if (text) {
        name = text;
      }
    } else if (content_type === contentTypes.testlet.type) {
      name = 'Case Study | Testlet';
      const { testlet_title } = testlet_information;
      if (testlet_title) {
        name = testlet_title;
      }
    }
  } catch {}

  if (isFileName && name.length > 50) {
    name = name.substring(0, 50);
  }

  return name;
};
