import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Form } from 'reactstrap';
import SocialAuthButtons from './SocialAuthButtons';
import withRedirect from '../../hoc/withRedirect';

const LoginForm = ({ setRedirect, hasLabel, layout }) => {
  // Handler
  const handleSubmit = e => {
    e.preventDefault();
  };

  function showWelcomeToast(email) {
    toast.success(`Logged in as ${email}`);
    toast(
      <Fragment>
        Welcome to <strong>NYU Dentistry</strong>!<br />
        adVANCE
      </Fragment>
    );
  }

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <SocialAuthButtons setEmail={showWelcomeToast} />
      </Form>
    </>
  );
};

LoginForm.propTypes = {
  setRedirect: PropTypes.func.isRequired,
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'basic',
  hasLabel: false
};

export default withRedirect(LoginForm);
