export const contentTypes = {
  testlet: {
    type: 'testlet',
    name: 'Case Study | Testlet',
    theme: 'info',
    enabled: true
  },
  question: {
    type: 'question',
    name: 'Stand-Alone Question',
    theme: 'success',
    enabled: true
  }
};
