import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, CardFooter, Col, FormGroup, Input, Label, Row, UncontrolledTooltip } from 'reactstrap';
import Select from 'react-select';
import ButtonIcon from '../../common/ButtonIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash';
import { isObjectNullOrEmpty, isUserAccessOfTypeAdmin } from '../../../helpers/inbdeUtils';
import { answer } from '../../../repository/content/models/answer';
import { StandAloneQuestionFormAnswerChoice } from './StandAloneQuestionFormAnswerChoice';
import { isIterableArray } from '../../../helpers/utils';
import { toast } from 'react-toastify';
import { getCollaboratorModel } from '../../testletForm/commons';

const customReactSelectStyles = {
  option: (provided, state) => {
    const color = state.isDisabled ? 'black' : state.isSelected ? 'white' : '';
    const fontWeight = state.isDisabled ? 800 : '';

    return { ...provided, color, fontWeight };
  }
};
const maxAllowedAnswers = 6;

export const StandAloneQuestionFormContent = ({
  activeUsers,
  attachmentModel,
  fetchUsersFromDB,
  formOptions,
  isAttachmentRemoved,
  isSubmitQuestionForm,
  markQuestionAsUpdated,
  persistQuestion,
  previewAttachment,
  questionData,
  signedInUser,
  updateCollaborators,
  uploadAttachment
}) => {
  // state and variable declaration
  const { collaboratorIds, created_by, inbde_course, question } = questionData;
  const initialCollaborators = activeUsers.filter(user => user.value !== created_by);
  const [attachmentToAddIn, setAttachmentToAddIn] = useState(null);
  const [attachmentToRemove, setAttachmentToRemove] = useState(null);
  const [attachmentToRemoveFrom, setAttachmentToRemoveFrom] = useState(null);
  const [collaboratorIdState, setCollaboratorIdState] = useState(collaboratorIds);
  const [createdByState, setCreatedByState] = useState(created_by);
  const [collaboratorOptions, setCollaboratorOptions] = useState(initialCollaborators);
  const [previousQuestionData, setPreviousQuestionData] = useState(questionData);
  const [inbdeCourse, setInbdeCourse] = useState(inbde_course);
  const [questionState, setQuestionState] = useState(question);
  const [updateCollaboratorAction, setUpdateCollaboratorAction] = useState(null);
  const [updatedCollaborator, setUpdatedCollaborator] = useState(null);

  const { access_type } = signedInUser;
  const isUserAdmin = isUserAccessOfTypeAdmin(access_type);

  const finalAnswersRef = useRef([]);
  const mountedRef = useRef(null);

  const answers = questionState['answers'] ? questionState['answers'] : [];
  const questionStemAttachments =
    questionState['question_stem'] && questionState['question_stem'].attachments
      ? questionState['question_stem'].attachments
      : [];
  const reviewMaterials = questionState['review_material'] ? questionState['review_material'] : [];

  // method definitions
  const updateQuestion = (fieldName, newValue) => {
    questionState[fieldName] = newValue;
    setQuestionState(questionState);
    markQuestionAsUpdated();
  };

  const addAttachments = question => {
    if (attachmentToAddIn === 'review') {
      question['review_material'].push(attachmentModel);
    } else if (attachmentToAddIn === 'question_stem') {
      question['question_stem']['attachments'].push(attachmentModel);
    }
  };

  const addAnswerChoice = () => {
    const { answers } = questionState;

    if (answers.length >= maxAllowedAnswers) {
      toast.error('Cannot add more answers');
      return;
    }

    const newAnswerChoice = JSON.parse(JSON.stringify(answer));
    newAnswerChoice['id'] = answers.length;

    answers.push(newAnswerChoice);
    updateQuestion('answers', answers);
  };

  const deleteAnswerChoice = id => {
    const { answers } = questionState;
    const newAnswers = answers.filter(answer => {
      return answer.id !== id;
    });

    for (let i = 0; i < newAnswers.length; i += 1) {
      newAnswers[i].id = i;
    }

    updateQuestion('answers', newAnswers);
  };

  const deleteAttachments = question => {
    try {
      if (attachmentToRemoveFrom === 'review') {
        question['review_material'] = question['review_material'].filter(item => item.url !== attachmentToRemove.url);
      } else if (attachmentToRemoveFrom === 'question_stem') {
        question['question_stem']['attachments'] = question['question_stem']['attachments'].filter(
          item => item.url !== attachmentToRemove.url
        );
      } else {
        // delete answer attachments
        const answerKey = attachmentToRemoveFrom.split(':');
        const key = answerKey[0];
        const answerId = parseInt(answerKey[1]);

        for (let i = 0; i < question['answers'].length; i += 1) {
          const { id } = question['answers'][i];
          if (answerId === id) {
            if (key === 'answer_choice') {
              question['answers'][i]['answer_choice']['attachments'] = question['answers'][i]['answer_choice'][
                'attachments'
              ].filter(item => item.url !== attachmentToRemove.url);
            } else if (key === 'answer_explanation') {
              question['answers'][i]['explanation']['attachments'] = question['answers'][i]['explanation'][
                'attachments'
              ].filter(item => item.url !== attachmentToRemove.url);
            }

            break;
          }
        }
      }
    } catch {}
  };

  const getUpdatedCollaborators = (questionData, question) => {
    // removing all collaborators
    if (updatedCollaborator === null) {
      setCollaboratorIdState([]);
      questionData['collaboratorIds'] = [];
      question['collaborators'] = [];
      return;
    }

    const isAddCollaborator = updateCollaboratorAction === 'add';
    let collaboratorIdsList = questionData['collaboratorIds'];

    if (!Array.isArray(question['collaborators'])) {
      question['collaborators'] = [];
    }

    if (!Array.isArray(questionData['collaboratorIds'])) {
      collaboratorIdsList = [];
    }

    const { value: userId } = updatedCollaborator;
    // adding one collaborator
    if (isAddCollaborator) {
      question['collaborators'].push(updatedCollaborator);
      collaboratorIdsList.push(userId);
    } else {
      // removing one collaborator
      question['collaborators'] = question['collaborators'].filter(user => user.value !== userId);
      collaboratorIdsList = collaboratorIdsList.filter(id => id !== userId);
    }
    questionData['collaboratorIds'] = collaboratorIdsList;
    setCollaboratorIdState(collaboratorIdsList);
  };

  const handlePreviewAttachment = (file, key) => {
    try {
      const fileType = file.content_type.split('/').pop();
      file.content_type = fileType;
      setAttachmentToRemoveFrom(key);
      setAttachmentToRemove(file);
      previewAttachment(file);
    } catch {
      toast.error('There was an error in loading this attachment');
    }
  };

  const handlePreviewAnswerAttachment = (file, key, answerId) => {
    const answerKey = key + ':' + answerId;
    handlePreviewAttachment(file, answerKey);
  };

  // collect and process question data before sending to be persisted
  const handleQuestionSubmit = updatedAnswers => {
    const updatedQuestionData = JSON.parse(JSON.stringify(questionData));
    const updatedQuestion = JSON.parse(JSON.stringify(questionState));

    updatedQuestionData['collaboratorIds'] = collaboratorIdState;
    updatedQuestionData['created_by'] = createdByState;

    const { course_semester, course_year, key_concepts, question_stem } = updatedQuestion;

    let ddsCourseSemester = null;
    if (course_year && course_year.label && course_semester && course_semester.label) {
      ddsCourseSemester = course_year.label + ', ' + course_semester.label;
    }

    if (updateCollaboratorAction) {
      getUpdatedCollaborators(updatedQuestionData, updatedQuestion);
    }

    updatedQuestion['answers'] = updatedAnswers;
    updatedQuestion['question_stem'] = {
      attachments: question_stem.attachments,
      text: question_stem.text.trim()
    };

    if (attachmentModel && attachmentToAddIn) {
      addAttachments(updatedQuestion);
      setAttachmentToAddIn(null);
    }

    if (isAttachmentRemoved && attachmentToRemove && attachmentToRemoveFrom) {
      deleteAttachments(updatedQuestion);
      setAttachmentToRemoveFrom(null);
      setAttachmentToRemove(null);
    }

    updatedQuestion['dds_course_semester'] = ddsCourseSemester;
    updatedQuestion['key_concepts'] = typeof key_concepts === 'string' ? key_concepts.trim().split(',') : key_concepts;

    updatedQuestionData['question'] = updatedQuestion;
    updatedQuestionData['inbde_course'] = inbdeCourse;
    persistQuestion(updatedQuestionData);

    setUpdateCollaboratorAction(null);
    setUpdatedCollaborator(null);
  };

  const handleUpdateCollaborators = updatedValues => {
    try {
      const { collaborators } = questionState;
      let action = null;

      if (updatedValues) {
        if (collaborators.length < updatedValues.length) {
          action = 'add';
        } else {
          action = 'remove';
        }
      } else {
        if (isIterableArray(collaborators)) {
          action = 'remove';
        }
      }

      if (!action) {
        return;
      }

      if (Array.isArray(updatedValues) && updatedValues.length === 0) {
        updatedValues = null;
      }

      let collaboratorEmail = '';
      if (updatedValues !== null) {
        const isToAdd = action === 'add';
        updatedValues = getCollaboratorModel(collaborators, updatedValues, isToAdd);
        const { email } = updatedValues;
        collaboratorEmail = email;
      }

      setUpdateCollaboratorAction(action);
      setUpdatedCollaborator(updatedValues);

      updateCollaborators(action, collaboratorEmail);
    } catch (err) {
      toast.error('Could not update collaborators at this moment');
    }
  };

  const handleUpdateQuestionCreator = newCreator => {
    if (isObjectNullOrEmpty(newCreator)) {
      return;
    }

    try {
      const { value: newCreatorId, label, name } = newCreator;
      const updatedCollaboratorIds = collaboratorIdState.filter(id => id !== newCreatorId);
      const updatedCollaborators = questionState['collaborators'].filter(user => user.value !== newCreatorId);

      questionState['question_creator'] = name || label;
      questionState['created_by'] = newCreatorId;
      questionState['collaborators'] = updatedCollaborators;
      setCreatedByState(newCreatorId);
      setCollaboratorIdState(updatedCollaboratorIds);
      markQuestionAsUpdated();
    } catch {
      toast.error('Unable to change question creator');
    }
  };

  const handleUploadAttachment = key => {
    setAttachmentToAddIn(key);
    uploadAttachment(key);
  };

  const handleUploadAnswerAttachment = key => {
    uploadAttachment(key);
  };

  const processAnswer = answer => {
    finalAnswersRef.current.push(answer);
    if (finalAnswersRef.current.length === questionState['answers'].length) {
      handleQuestionSubmit(finalAnswersRef.current);
      finalAnswersRef.current = [];
    }
  };

  // use effect hook for real-time change and collaboration
  useEffect(() => {
    mountedRef.current = true;
    finalAnswersRef.current = [];

    function updateQuestionFromProps() {
      const { question } = questionData;
      if (mountedRef.current) {
        setPreviousQuestionData(questionData);
        setQuestionState(question);
      }
    }

    if (!isEqual(questionData, questionState) && !isEqual(previousQuestionData, questionData)) {
      updateQuestionFromProps();
    }
    return () => {
      mountedRef.current = false;
    };
  }, [previousQuestionData, questionData, questionState]);

  useEffect(() => {
    const collaborators = activeUsers.filter(user => user.value !== created_by);
    setCollaboratorOptions(collaborators);
  }, [activeUsers, created_by]);

  return (
    <Card className="mb-3">
      <div id="stand-alone-question" />
      <CardBody id="cardBodyContainer" className="d-block">
        <Row>
          <FormGroup className="col-md-6 col-sm-12">
            <Label for="readonly" className="fs-0">
              DDS Course <strong className="text-danger">&#42;</strong>
            </Label>
            <Select
              onChange={course => {
                updateQuestion('course', course);
              }}
              options={formOptions.courseOptions}
              value={questionState['course']}
              styles={customReactSelectStyles}
              required
            />
          </FormGroup>
          <FormGroup className="col-md-6 col-sm-12">
            <Label for="readonly" className="fs-0">
              Semester of course <strong className="text-danger">&#42;</strong>
            </Label>
            <Select
              onChange={semester => {
                updateQuestion('course_semester', semester);
              }}
              options={formOptions.semesterOptions}
              value={questionState['course_semester']}
              required
            />
          </FormGroup>
        </Row>

        <Row>
          <div className="col-md-6 col-sm-12">
            <FormGroup>
              <Label for="exampleName" className="fs-0">
                Created By <strong className="text-danger">&#42;</strong>
              </Label>
              <Select
                className="bg-testlet-form-disabled"
                isDisabled={!isUserAdmin}
                name="question_creator_name"
                type="text"
                value={{ label: questionState['question_creator'], value: createdByState }}
                options={collaboratorOptions}
                onChange={change => handleUpdateQuestionCreator(change)}
                onMenuScrollToBottom={() => fetchUsersFromDB()}
              />
            </FormGroup>
          </div>
          <FormGroup className="col-md-6 col-sm-12">
            <Label for="readonly" className="fs-0">
              Question Collaborators
            </Label>
            <Select
              name="question-collaborators"
              type="select"
              value={questionState['collaborators']}
              isMulti
              options={collaboratorOptions}
              onChange={change => handleUpdateCollaborators(change)}
              onMenuScrollToBottom={() => fetchUsersFromDB()}
            />
          </FormGroup>
        </Row>

        <Row>
          <FormGroup className="col-md-6 col-sm-12">
            <Label for="readonly" className="fs-0">
              Scientific Basis (check all that apply) <strong className="text-danger">&#42;</strong>
            </Label>
            <Select
              onChange={values => {
                values = values || [];
                updateQuestion('foundational_knowledge', values);
              }}
              options={formOptions.fkOptions}
              value={questionState['foundational_knowledge']}
              isMulti
              styles={customReactSelectStyles}
              hideSelectedOptions={false}
            />
          </FormGroup>
          <FormGroup className="col-md-6 col-sm-12">
            <Label for="readonly" className="fs-0">
              Provision of Clinical Services (check all that apply) <strong className="text-danger">&#42;</strong>
            </Label>
            <Select
              id="clinical-content"
              onChange={values => {
                values = values || [];
                updateQuestion('clinical_content', values);
              }}
              options={formOptions.ccOptions}
              value={questionState['clinical_content']}
              isMulti
              styles={customReactSelectStyles}
              hideSelectedOptions={false}
            />
          </FormGroup>
        </Row>

        <Row>
          <FormGroup className="col-md-6 col-sm-12">
            <Label for="readonly" className="fs-0" id="mock-exam">
              Exam
            </Label>
            <Select
              onChange={values => {
                values = values || [];
                updateQuestion('mockExam', values);
              }}
              options={formOptions.inbdeCourse}
              value={questionState['mockExam']}
              isDisabled={!isUserAdmin}
              isMulti
            />
            <UncontrolledTooltip placement="top" target="mock-exam">
              This field can only be accessed by the admins and denotes the exam where this question will be used.
            </UncontrolledTooltip>
          </FormGroup>
          <FormGroup className="col-md-6 col-sm-12">
            <Label for="readonly" className="fs-0">
              Level of Difficulty <strong className="text-danger">&#42;</strong>
            </Label>
            <Select
              id="difficulty-level"
              onChange={level => {
                updateQuestion('difficulty_level', level);
              }}
              options={formOptions.diffcultyLevelOptions}
              value={questionState['difficulty_level']}
            />
          </FormGroup>
        </Row>

        <Row>
          <FormGroup className="col-md-6 col-sm-12">
            <Label for="readonly" className="fs-0">
              NBDHE Prep Course <strong className="text-danger">&#42;</strong>
            </Label>
            <Select
              value={inbdeCourse}
              onChange={updatedInbdeCourse => {
                setInbdeCourse(updatedInbdeCourse);
                markQuestionAsUpdated();
              }}
              options={formOptions.inbdeCourse}
              required
            />
          </FormGroup>
          <FormGroup className="col-md-6 col-sm-12">
            <Label for="exampleName" className="fs-0">
              Key Concepts <strong className="text-danger">&#42;</strong>
            </Label>
            <Input
              type="text"
              name="key-concepts"
              placeholder="A comma seperated list of concepts"
              onChange={e => {
                updateQuestion('key_concepts', e.target.value);
              }}
              value={questionState['key_concepts']}
              required
            />
          </FormGroup>
        </Row>

        <Row>
          <Col>
            <FormGroup>
              <Label for="exampleText" className="fs-1">
                Question stem <strong className="text-danger">&#42;</strong>
              </Label>
              <Input
                type="textarea"
                rows="4"
                name="text"
                id="question-stem"
                value={questionState['question_stem']['text']}
                onChange={e => {
                  const updatedQuestionStem = questionState['question_stem'];
                  updatedQuestionStem.text = e.target.value;
                  updateQuestion('question_stem', updatedQuestionStem);
                }}
                required
              />
              <p className="mb-0">
                <Button className="pl-0 fs--1" color="link" onClick={() => handleUploadAttachment('question_stem')}>
                  Upload an image
                </Button>
              </p>
              <div className="d-flex">
                <div className="mr-auto align-self-center">
                  {questionStemAttachments.map((item, index) => {
                    item = item || { file_name: '' };
                    return (
                      <div className="mb-1" key={index}>
                        <FontAwesomeIcon
                          icon="check-circle"
                          transform="grow-0"
                          className="d-inline mr-2 text-primary"
                        />
                        <Button
                          color="link primary"
                          className="p-0 fs--1 font-weight-bold"
                          onClick={() => handlePreviewAttachment(item, 'question_stem')}
                        >
                          {item.file_name}
                        </Button>
                      </div>
                    );
                  })}
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>

        {answers.map(answer => {
          return (
            <StandAloneQuestionFormAnswerChoice
              key={answer.id}
              answerChoice={answer}
              attachmentModel={attachmentToAddIn === null ? attachmentModel : null}
              deleteAnswer={deleteAnswerChoice}
              isSubmitAnswerChoice={isSubmitQuestionForm}
              markAnswerAsUpdated={markQuestionAsUpdated}
              persistAnswer={processAnswer}
              previewAttachment={handlePreviewAnswerAttachment}
              questionId={questionState['uuid']}
              uploadAttachment={handleUploadAnswerAttachment}
            />
          );
        })}

        <Row className="mt-4">
          <Col>
            <ButtonIcon
              className="mr-2"
              color="falcon-primary"
              icon="plus"
              transform="shrink-3"
              onClick={() => addAnswerChoice()}
            >
              Add answer choice
            </ButtonIcon>
          </Col>
        </Row>
      </CardBody>

      <CardFooter className="bg-100 mt-2 d-block">
        <div className="mb-2">
          <h5 className="fs-0">Review Materials</h5>
          <small>
            Upload review materials for students. Allowed file formats image only (e.g PNG, JPG, JPEG, PDF etc). No DOC
            or DOCX formats.
          </small>
        </div>
        <div className="d-flex">
          <div className="mr-auto align-self-center">
            {reviewMaterials.map((item, index) => {
              item = item || { file_name: '' };
              return (
                <div className="mb-1" key={index}>
                  <FontAwesomeIcon icon="check-circle" transform="grow-0" className="d-inline mr-2 text-primary" />
                  <Button
                    color="link primary"
                    className="p-0 fs--1 font-weight-bold"
                    onClick={() => handlePreviewAttachment(item, 'review')}
                  >
                    {item.file_name}
                  </Button>
                </div>
              );
            })}
          </div>
        </div>
        <div className="p-2 row justify-content-between">
          <ButtonIcon
            className="ml-2"
            color="primary"
            icon="link"
            transform="shrink-2"
            onClick={() => handleUploadAttachment('review')}
          >
            Upload
          </ButtonIcon>
        </div>
      </CardFooter>
    </Card>
  );
};

StandAloneQuestionFormContent.propTypes = {
  activeUsers: PropTypes.array,
  attachmentModel: PropTypes.object,
  fetchUsersFromDB: PropTypes.func,
  formOptions: PropTypes.object.isRequired,
  isAttachmentRemoved: PropTypes.bool,
  isSubmitQuestionForm: PropTypes.bool,
  markQuestionAsUpdated: PropTypes.func,
  persistQuestion: PropTypes.func.isRequired,
  previewAttachment: PropTypes.func,
  questionData: PropTypes.object.isRequired,
  signedInUser: PropTypes.object.isRequired,
  updateCollaborators: PropTypes.func,
  uploadAttachment: PropTypes.func
};

StandAloneQuestionFormContent.defaultProps = {
  activeUsers: [],
  attachmentModel: null,
  fetchUsersFromDB: () => {},
  isAttachmentRemoved: false,
  isSubmitQuestionForm: false,
  markQuestionAsUpdated: () => {},
  previewAttachment: () => {},
  updateCollaborators: () => {},
  uploadAttachment: () => {}
};
