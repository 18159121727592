import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';
import '../Firebase/FirebaseService';
import 'firebase/auth';
import algoliasearch from 'algoliasearch';
import { createNullCache } from '@algolia/cache-common';
import { getCurrentTimeStamp, isProd } from '../../helpers/inbdeUtils';
import { toast } from 'react-toastify';
import { isIterableArray } from '../../helpers/utils';
import { projectName } from '../../helpers/constants';

// Environment variables
const isProdEnv = isProd();
const REACT_APP_ALGOLIA_APP_ID = isProdEnv ? 'REACT_APP_ALGOLIA_APP_ID_PROD' : 'REACT_APP_ALGOLIA_APP_ID_QA';
const REACT_APP_ALGOLIA_SEARCH_KEY = isProdEnv
  ? 'REACT_APP_ALGOLIA_SEARCH_KEY_PROD'
  : 'REACT_APP_ALGOLIA_SEARCH_KEY_QA';

const ALGOLIA_APP_ID = process.env[REACT_APP_ALGOLIA_APP_ID];
const ALGOLIA_SEARCH_KEY = process.env[REACT_APP_ALGOLIA_SEARCH_KEY];
const ALGOLIA_INDEX_NAME = process.env.REACT_APP_USERS_ALGOLIA_INDEX_NAME;

// Initalize Algolia Search
const client = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY, {
  responsesCache: createNullCache() // disable response cache to render updated testlets
});
const userIndex = client.initIndex(ALGOLIA_INDEX_NAME);

// Get a reference to the database service
var database = firebase.firestore();
var users = database.collection('users');
var whitelist = database.collection('user_whitelist');

const analytics = firebase.analytics();

class UserService {
  unsubscribe = null;

  addUsersToWhitelist(usersToAdd, callback) {
    try {
      if (isIterableArray(usersToAdd)) {
        const batch = database.batch();
        const currentTimeStamp = getCurrentTimeStamp();

        usersToAdd.forEach(async userDetails => {
          let { email, access_type, projects: whitelistProjects, user } = userDetails;

          const entryRef = whitelist.doc(email);

          if (isIterableArray(whitelistProjects)) {
            whitelistProjects = [...whitelistProjects, projectName];
          } else {
            whitelistProjects = [projectName];
          }

          batch.set(entryRef, { access_type, projects: whitelistProjects }, { merge: true });

          if (user !== null) {
            let { id: userId, projects } = user;
            const userRef = users.doc(userId);

            if (isIterableArray(projects)) {
              projects = [...projects, projectName];
            } else {
              projects = [projectName];
            }

            batch.update(userRef, {
              access_type,
              updated_on: currentTimeStamp,
              deactivated_on: null,
              deleted_on: null,
              is_active: 'active',
              projects
            });
          }
        });

        batch
          .commit()
          .then(() => {
            callback(true);
          })
          .catch(() => {
            callback(false);
          });
      } else {
        callback(false);
      }
    } catch {
      callback(false);
    }
  }

  activateUser(userId, userEmail, accessType, projects) {
    try {
      const batch = database.batch();

      if (isIterableArray(projects)) {
        projects = [...projects, projectName];
      } else {
        projects = [projectName];
      }

      // create user entry in whitelist with the correct access type
      const whitelistRef = whitelist.doc(userEmail);
      batch.set(whitelistRef, { access_type: accessType, projects });

      // reactive user with the correct access type
      const currentTimeStamp = getCurrentTimeStamp();
      const userRef = users.doc(userId);
      batch.update(userRef, {
        access_type: accessType,
        updated_on: currentTimeStamp,
        deactivated_on: null,
        is_active: 'active',
        projects
      });

      batch
        .commit()
        .then(() => {
          toast.success('Successfully activated user account');
        })
        .catch(() => {
          toast.error('Could not activate user at the moment');
        });
    } catch {
      toast.error('Could not activate user at the moment');
    }
  }

  changeUserAccessLevel(userId, userEmail, newAccessType) {
    try {
      const batch = database.batch();

      // update access level in whitelist
      const whitelistRef = whitelist.doc(userEmail);
      batch.update(whitelistRef, { access_type: newAccessType });

      if (userId !== userEmail) {
        const currentTimeStamp = getCurrentTimeStamp();
        const userRef = users.doc(userId);
        batch.update(userRef, { access_type: newAccessType, updated_on: currentTimeStamp });
      }

      batch
        .commit()
        .then(() => {
          toast.success('Successfully changed access level of user');
        })
        .catch(() => {
          toast.error('Could not change access level of user at the moment');
        });
    } catch {
      toast.error('Could not change access level of user at the moment');
    }
  }

  async createUser(user) {
    try {
      await users.doc(user.props.id).set({
        full_name: user.props.full_name,
        given_name: user.props.given_name,
        email: user.props.email,
        image_url: user.props.image_url,
        access_type: user.props.access_type,
        is_active: user.props.is_active,
        created_on: user.props.created_on,
        deleted_on: user.props.deleted_on,
        deactivated_on: user.props.deactivated_on,
        last_login: user.props.last_login,
        updated_on: user.props.updated_on,
        is_logged_in: user.props.isLoggedIn,
        user_device: user.props.user_device,
        google_id_token: user.props.google_id_token,
        projects: user.props.projects
      });
      return true;
    } catch (e) {
      const userId = user && user.props ? user.props.id : null;
      this.logError({ uid: userId }, e, 'Error in creating new user');
      return false;
    }
  }

  deactivateUser(userId, userEmail, projects) {
    try {
      const batch = database.batch();

      if (isIterableArray(projects)) {
        projects = projects.filter(project => project !== projectName);
      } else {
        projects = [];
      }

      const isProjectListEmpty = !isIterableArray(projects);

      // remove user from whitelist
      const whitelistRef = whitelist.doc(userEmail);
      if (isProjectListEmpty) {
        batch.delete(whitelistRef);
      } else {
        batch.update(whitelistRef, { projects });
      }

      // mark user as deactivated
      const userRef = users.doc(userId);
      const currentTimeStamp = getCurrentTimeStamp();

      const updatePayload = {
        updated_on: currentTimeStamp,
        projects
      };
      if (isProjectListEmpty) {
        updatePayload.is_active = 'deactivated';
        updatePayload.deactivated_on = currentTimeStamp;
      }

      batch.update(userRef, updatePayload);

      batch
        .commit()
        .then(() => {
          toast.success('Successfully deactivated user account');
        })
        .catch(() => {
          toast.error('Could not deactivate user at the moment');
        });
    } catch {
      toast.error('Could not deactivate user at the moment');
    }
  }

  deleteUser(userId, userEmail, projects) {
    try {
      const batch = database.batch();

      if (isIterableArray(projects)) {
        projects = projects.filter(project => project !== projectName);
      } else {
        projects = [];
      }

      const isProjectListEmpty = !isIterableArray(projects);

      // remove user from whitelist if an entry exists
      const whitelistRef = whitelist.doc(userEmail);
      if (isProjectListEmpty) {
        batch.delete(whitelistRef);
      } else {
        batch.update(whitelistRef, { projects });
      }

      // mark user as deactivated
      if (userId !== userEmail) {
        const userRef = users.doc(userId);
        const currentTimeStamp = getCurrentTimeStamp();
        batch.update(userRef, {
          deleted_on: isProjectListEmpty ? currentTimeStamp : null,
          updated_on: currentTimeStamp,
          projects
        });
      }

      batch
        .commit()
        .then(() => {
          toast.success('Successfully deleted user');
        })
        .catch(() => {
          toast.error('Could not delete user at the moment');
        });
    } catch {
      toast.error('Could not delete user at the moment');
    }
  }

  // TO-DO: optimize query or see if we can remove this query
  getListOfAdmins() {
    return users
      .where('projects', 'array-contains', projectName)
      .where('deleted_on', '==', null)
      .where('deactivated_on', '==', null)
      .where('access_type', '==', 'admin')
      .get()
      .then(validUsers => {
        const admins = [];
        validUsers.forEach(user => {
          admins.push(user.id);
        });
        return admins;
      })
      .catch(err => {
        this.logError(null, err, 'Error in getting list of admins');
        return [];
      });
  }

  // optimize query
  getListOfUsers(end, callback) {
    this.unsubscribe && this.unsubscribe();
    this.unsubscribe = users
      .where('projects', 'array-contains', projectName)
      .where('deleted_on', '==', null)
      .onSnapshot(
        querySnapshot => {
          callback(querySnapshot);
        },
        function onError(e) {
          console.error(e);
        }
      );

    end && this.unsubscribe && this.unsubscribe();
  }

  async getListOfActiveUsersPaginated(accessType, startAfter, pageLimit) {
    try {
      const query = users
        .where('projects', 'array-contains', projectName)
        .where('deleted_on', '==', null)
        .where('deactivated_on', '==', null)
        .where('access_type', 'in', accessType)
        .orderBy('full_name', 'asc');

      const paginatedQuery = startAfter ? query.startAfter(startAfter).limit(pageLimit) : query.limit(pageLimit);
      return (await paginatedQuery.get()).docs;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  /* @Deprecated: Not in Use */
  async getListOfUsersPaginated(accessType, startAfter, pageLimit) {
    try {
      const query = users
        .where('projects', 'array-contains', projectName)
        .where('deleted_on', '==', null)
        .where('access_type', 'in', accessType)
        .orderBy('full_name', 'asc')
        .limit(pageLimit);

      const querySnapshot = startAfter ? await query.startAfter(startAfter).get() : await query.get();
      return querySnapshot.docs;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  /* @Deprecated: Not in Use */
  getListOfUsersPaginatedSnapshot(accessType, startAfter, pageLimit, end, callback) {
    this.unsubscribe && this.unsubscribe();
    let query = users
      .where('projects', 'array-contains', projectName)
      .where('deleted_on', '==', null)
      .where('access_type', 'in', accessType)
      .orderBy('full_name', 'asc')
      .limit(pageLimit);

    if (startAfter) {
      query = query.startAfter(startAfter);
    }

    this.unsubscribe = query.startAfter(startAfter).onSnapshot(
      function(querySnapshot) {
        callback(querySnapshot);
      },
      function onError(e) {
        console.error(e);
      }
    );

    end && this.unsubscribe && this.unsubscribe();
  }

  async getUserDetails(userId) {
    try {
      const userDoc = await users.doc(userId).get();
      return userDoc.exists ? userDoc : null;
    } catch (err) {
      this.logError({ uid: userId }, err, 'Error in getting user details');
      return null;
    }
  }

  async isUserInWhitelist(key) {
    try {
      const doc = await whitelist.doc(key).get();
      if (doc.exists) {
        return doc.data();
      }
      return null;
    } catch (err) {
      this.logError({ uid: key }, err, 'Error in checking if user is in whitelist');
      return null;
    }
  }

  isUserLoggedIn(userId, end, callback) {
    this.unsubscribe && this.unsubscribe();
    if (userId) {
      this.unsubscribe = users.doc(userId).onSnapshot(
        doc => {
          const data = doc.data() ? doc.data() : null;
          callback(data);
        },
        err => {
          this.logError({ uid: userId }, err, 'Error in getting is_user_logged_in data');
          callback(null);
        }
      );
      end && this.unsubscribe && this.unsubscribe();
    } else {
      this.unsubscribe && this.unsubscribe();
    }
  }

  logError(user, e, altMessage) {
    let userId = null;
    if (user) {
      analytics.setUserProperties({ user });
      userId = user.uid;
    }

    const timestamp = getCurrentTimeStamp();
    analytics.logEvent('error', {
      class: 'UserService',
      name: e && e.name ? e.name : null,
      rest: e && e.message ? e.message : altMessage,
      stackTrace: e && e.stack ? e.stack : null,
      userId,
      timestamp
    });
  }

  // TODO: Integrate method with search form to search users
  async searchUserByParam(query, params) {
    try {
      const response = await userIndex.search(query, { ...params, typoTolerance: false });
      return response.hits;
    } catch (e) {
      return [];
    }
  }

  async updateUserLogin(firebaseUser, data) {
    try {
      const { uid: userId } = firebaseUser;
      await users.doc(userId).update(data);
      return true;
    } catch {
      return false;
    }
  }
}

export default UserService;
