import { contentTypes } from '../../../data/content/contentTypes';
import { isObjectNullOrEmpty, isStringNullOrEmpty, isUserAccessOfTypeAdmin } from '../../../helpers/inbdeUtils';
import { testletStatusTypes } from '../../../helpers/testletTypes';
import { isIterableArray } from '../../../helpers/utils';

const isUserPartOfQuestion = (collaboratorIds, createdBy, signedInUserId) => {
  if (signedInUserId === createdBy) {
    return true;
  }

  if (isIterableArray(collaboratorIds) && collaboratorIds.includes(signedInUserId)) {
    return true;
  }

  return false;
};

export const validateAccess = (questionData, user) => {
  if (isObjectNullOrEmpty(questionData)) {
    throw new Error('No data found against provided quesiton ID');
  }

  if (user === null || user === undefined) {
    throw new Error('No signed in user found');
  }

  const { access_type, uid } = user;
  const { collaboratorIds, testlet_type: content_status, created_by, is_deleted, is_flagged } = questionData;

  if (is_deleted) {
    throw new Error('This question has been deleted');
  }

  // admins can edit any type of content except deleted
  if (isUserAccessOfTypeAdmin(access_type)) {
    return;
  }

  if (is_flagged) {
    throw new Error('Question is flagged and cannot be edited');
  }

  if (
    testletStatusTypes.IN_PROGRESS.includes(content_status) &&
    isUserPartOfQuestion(collaboratorIds, created_by, uid)
  ) {
    return;
  }

  throw new Error('You are not allowed to edit this question');
};

export const validateSave = questionData => {
  if (questionData === undefined || questionData === null) {
    throw new Error('question data is missing');
  }

  const { content_type } = questionData;
  if (content_type !== contentTypes.question.type) {
    throw new Error('invalid content type has been set for this question');
  }

  if (isStringNullOrEmpty(questionData['created_by'])) {
    throw new Error('question creator field is invalid or missing');
  }

  if (questionData['is_deleted']) {
    throw new Error('the question is deleted');
  }

  if (isStringNullOrEmpty(questionData['testlet_type'])) {
    throw new Error('question status type is invalid or missing');
  }
};

export const validateSubmission = questionData => {
  if (!testletStatusTypes.IN_PROGRESS.includes(questionData['testlet_type'])) {
    throw new Error('question is not a draft');
  }

  const { created_by, inbde_course, question } = questionData;
  const {
    clinical_content,
    course,
    course_semester,
    created_by: questionCreator,
    foundational_knowledge,
    key_concepts,
    question_stem,
    answers,
    difficulty_level
  } = question;

  if (created_by !== questionCreator) {
    throw new Error('question creator field is invalid');
  }

  if (isObjectNullOrEmpty(inbde_course)) {
    throw new Error('NBDHE Prep Course field is empty');
  }

  const { text } = question_stem;
  if (isStringNullOrEmpty(text)) {
    throw new Error('Question stem text field is empty');
  }

  if (!isIterableArray(answers) || (isIterableArray(answers) && answers.length < 2)) {
    throw new Error('number of answers is less than required');
  }

  for (let i = 0; i < answers.length; i += 1) {
    const answer = answers[i];
    const { answer_choice, explanation } = answer;
    const { text } = answer_choice;
    const { text: explanationText } = explanation;

    if (isStringNullOrEmpty(text)) {
      throw new Error(`Answer Choice ${i + 1} field is empty`);
    }

    if (isStringNullOrEmpty(explanationText)) {
      throw new Error(`Explanation ${i + 1} field is empty`);
    }
  }

  if (!isIterableArray(clinical_content)) {
    throw new Error('Provision of Clinical Services  field is empty');
  }

  if (!isIterableArray(foundational_knowledge)) {
    throw new Error('Scientific Basis field is empty');
  }

  if (!isIterableArray(key_concepts)) {
    throw new Error('Key Concepts field is empty');
  }

  if (isObjectNullOrEmpty(course)) {
    throw new Error('DDS Course field is empty');
  }

  if (isObjectNullOrEmpty(course_semester)) {
    throw new Error('Course Semester field is empty');
  }

  if (isObjectNullOrEmpty(difficulty_level)) {
    throw new Error('Level of Difficulty field is empty');
  }
};
