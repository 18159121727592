import React, { useState } from 'react';
import Container from 'reactstrap/es/Container';
import { Col, Row } from 'reactstrap';

import Background from '../components/common/Background';
import HeaderLogo from '../components/common/HeaderLogo';

const withAuthSplit = OriginalComponent => {
  const UpdatedComponent = props => {
    // State
    const [bgProps, setBgProps] = useState(null);

    return (
      <Container fluid>
        <Row className="min-vh-100 bg-100">
          <Col xs={6} className="d-none d-lg-block">
            {bgProps && <Background {...bgProps} />}
          </Col>
          <Col>
            <HeaderLogo />
            <div className="row px-sm-0 align-self-center mx-auto py-3">
              <Row noGutters className="justify-content-center min-vh-75">
                <Col md={8} lg={9} xl={8} className="col-xxl-7 my-auto">
                  <div>
                    <div className="bg-nyu text-center p-2 border-bottom rounded-capsule">
                      <div className="z-index-1 position-relative p-4">
                        <div className="fs-2 mt-4 mb-2 text-100 text-sans-serif font-weight-bold">
                          With the power of the adVANCE app, you can easily create, edit, and collaborate with your
                          colleagues.
                        </div>
                      </div>
                    </div>
                    <div className="p-4">
                      <OriginalComponent setBgProps={setBgProps} {...props} />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    );
  };

  return UpdatedComponent;
};

export default withAuthSplit;
