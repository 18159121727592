import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';
import '../Firebase/FirebaseService';
import { ContentService } from '../Content/content';
import { getCurrentTimeStamp, convertToCSV, isProd } from '../../helpers/inbdeUtils';
import { projectName } from '../../helpers/constants';

// Get a reference to the database service
var database = firebase.firestore();
var whitelist = database.collection('user_whitelist');
const organizationList = database.collection('organization_members_list');
const whitelistVersions = database.collection('user_whitelist_metadata');

const isProdEnv = isProd();
const REACT_APP_FIREBASE_REGION = isProdEnv ? 'REACT_APP_FIREBASE_REGION_PROD' : 'REACT_APP_FIREBASE_REGION_QA';
const FIREBASE_FUNCTIONS_REGION = process.env[REACT_APP_FIREBASE_REGION] || 'us-central1'; // default for firebase functions
const functions = firebase.app().functions(FIREBASE_FUNCTIONS_REGION);

const analytics = firebase.analytics();

class WhiteListService {
  unsubscribe = null;
  contentService = new ContentService();

  async checkEmailInOrganizationList(email) {
    try {
      const emailEntry = await organizationList.doc(email).get();
      return { isSuccess: true, isExists: emailEntry.exists, data: emailEntry.data() };
    } catch (e) {
      return { isSuccess: false, isExists: undefined, data: null };
    }
  }

  async getEmailsInOrganizationList(emails) {
    try {
      const checkEmailsInOrganizationList = functions.httpsCallable('getUsersFromOrganizationList');
      const result = await checkEmailsInOrganizationList({ userEntries: { ...emails } });

      return result.data;
    } catch (error) {
      return null;
    }
  }

  getWhiteList(end, callback) {
    this.unsubscribe && this.unsubscribe();
    this.unsubscribe = whitelist.onSnapshot(
      querySnapshot => {
        callback(querySnapshot);
      },
      function() {}
    );

    end && this.unsubscribe && this.unsubscribe();
  }

  getWhiteListEntry(email, end, callback) {
    this.unsubscribe && this.unsubscribe();
    this.unsubscribe = whitelist.doc(email).onSnapshot(
      doc => {
        callback(doc.data());
      },
      err => {
        this.logError({ uid: email }, err, 'Error in getting whitelist entry');
        callback(null);
        end = true;
      }
    );

    end && this.unsubscribe && this.unsubscribe();
  }

  async getWhiteListPaginated(startAfter, pageLimit) {
    try {
      let query = whitelist
        .where('projects', 'array-contains', projectName)
        .where('is_user_signed_up', '==', false)
        .limit(pageLimit);
      if (startAfter) {
        query = query.startAfter(startAfter);
      }
      const result = await query.get();
      return result.docs;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  /* @Deprecated: Not in Use */
  getWhiteListPaginatedSnapshot(startAfter, pageLimit, end, callback) {
    this.unsubscribe && this.unsubscribe();

    let query = whitelist.limit(pageLimit);
    if (startAfter) {
      query = query.startAfter(startAfter);
    }
    this.unsubscribe = query.onSnapshot(
      querySnapshot => {
        callback(querySnapshot);
      },
      function() {}
    );

    end && this.unsubscribe && this.unsubscribe();
  }

  logError(user, e, altMessage) {
    let userId = null;
    if (user) {
      analytics.setUserProperties({ user });
      userId = user.uid;
    }

    const timestamp = getCurrentTimeStamp();
    analytics.logEvent('error', {
      class: 'WhitelistService',
      name: e && e.name ? e.name : null,
      rest: e && e.message ? e.message : altMessage,
      stackTrace: e && e.stack ? e.stack : null,
      userId,
      timestamp
    });
  }

  saveWhitelistVersion(currentWhitelist, user) {
    const fileLength = currentWhitelist.length;
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const currentDate = new Date().toLocaleDateString(options);
    const uploadedByName = user.displayName;
    const uploadedById = user.uid;
    const whitelistPath = 'whitelists/';

    const whiteListName = 'Whitelist - ' + currentDate + ' - ' + uploadedByName + ' - ' + fileLength + ' Users';
    const file = convertToCSV(currentWhitelist);
    const completePath = whitelistPath + whiteListName;

    this.contentService.uploadFileToStorage(
      completePath,
      file,
      { uploaded_by: uploadedById },
      () => {},
      async url => {
        try {
          await whitelistVersions.add({
            whitelist_url: url,
            whitelist_name: whiteListName,
            created_by: uploadedById,
            created_on: getCurrentTimeStamp(),
            path: completePath,
            users: currentWhitelist.length,
            isActive: null
          });
          return true;
        } catch (e) {
          return false;
        }
      }
    );
  }
}

export default WhiteListService;
