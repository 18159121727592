import React from 'react';

import nyuAdvanceLogo from '../../assets/img/illustrations/Advance_Logo.jpg';

const HeaderLogo = () => (
  <>
    <div className="col-12 justify-content-center align-items-center py-2 mt-4">
      <div className="d-grid">
        <img src={nyuAdvanceLogo} alt="NYU Advance" width={240} className="ml-auto mr-auto fs-0" />
      </div>
    </div>
  </>
);

export default HeaderLogo;
