import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import '../Firebase/FirebaseService';
import {
  customTestletEditRoute,
  customTestletViewRoute,
  customReviewTestletSubRoutes,
  customContentRoutes
} from '../../routes';
import { getDateAndTime, getCommentForChange, getCurrentTimeStamp, isProd, getBaseUrl } from '../../helpers/inbdeUtils';
import { isIterableArray } from '../../helpers/utils';
import { testletTypes } from '../../helpers/testletTypes';
import { contentTypes } from '../../data/content/content';

// set firebase functions regions (us-east1 for prod and us-central1 for qa)
const isProdEnv = isProd();
const REACT_APP_FIREBASE_REGION = isProdEnv ? 'REACT_APP_FIREBASE_REGION_PROD' : 'REACT_APP_FIREBASE_REGION_QA';
const FIREBASE_FUNCTIONS_REGION = process.env[REACT_APP_FIREBASE_REGION] || 'us-central1';

// Get a reference to the firebase services service
const functions = firebase.app().functions(FIREBASE_FUNCTIONS_REGION);
const database = firebase.firestore();
const comments = database.collection('comments');
const isEmailsPaused = true;
const inbdePlatformName = isProdEnv ? 'NYU-NBDHE' : 'QA-NBDHE';
// const storage = firebase.storage();

class CollaborationService {
  unsubscribe = null;

  checkIfCommentsUnread(testletId, sections, userId) {
    return comments
      .doc(testletId)
      .get()
      .then(res => {
        if (res) {
          const data = res.data();
          const readComments = {};
          sections.forEach(section => {
            if (data[section] && data[section].comments) {
              const comments = data[section].comments;
              const lastComment = comments[comments.length - 1];
              let isUserSeen = 0;
              if (lastComment && lastComment.seen_list) {
                isUserSeen =
                  lastComment.created_by_id !== userId ? lastComment.seen_list.filter(id => id === userId).length : 1;
              }
              readComments[section] = isUserSeen;
            }
            return 0;
          });
          return readComments;
        }
      })
      .catch(() => false);
  }

  async createComment(testletId, commentId, createdByDetails, commentDetails, collaborators) {
    const commentModel = this.getCommentModel(testletId, commentId, createdByDetails, commentDetails);
    const section = commentDetails.section === 'all' ? 'testlet-information' : commentDetails.section;
    const userModel = {
      id: commentModel.created_by_id,
      avatarSrc: commentModel.created_by_avatar,
      name: commentModel.created_by_name
    };

    try {
      await comments.doc(testletId).set(
        {
          [section]: {
            comments: firebase.firestore.FieldValue.arrayUnion(commentModel)
          },
          users: firebase.firestore.FieldValue.arrayUnion(userModel)
        },
        { merge: true }
      );

      // To send email about comment
      commentModel.section = section;
      commentModel.sectionTitle = commentDetails.sectionTitle;
      commentModel.formatDate = getDateAndTime(commentModel.created_at);
      this.sendNotificationAndEmail(testletId, collaborators, commentModel);

      return true;
    } catch (e) {
      return false;
    }
  }

  getCommentModel(testletId, commentId, createdByDetails, commentDetails) {
    return {
      id: commentId,
      testlet_id: testletId,
      created_at: commentDetails.createdAt,
      created_by_id: createdByDetails.uid,
      created_by_name: createdByDetails.displayName,
      created_by_avatar: createdByDetails.photoURL,
      deleted_at: null,
      comment: {
        text: commentDetails.message,
        attachments: commentDetails.attachments
      },
      section: commentDetails.section,
      emojis: {},
      seen_list: []
    };
  }

  getCommentsForTestlet(testletId, end, callback) {
    this.unsubscribe && this.unsubscribe();
    this.unsubscribe = comments.doc(testletId).onSnapshot(
      doc => {
        callback(doc);
      },
      function() {}
    );

    end && this.unsubscribe && this.unsubscribe();
  }

  markCommentsAsViewed(user, testletId, commentsData, isUserExist) {
    for (let section in commentsData) {
      let commentsUpdated = false;
      const sectionComments = commentsData[section].comments;
      // checking for sections that do not have comments e.g. users
      if (sectionComments) {
        for (let i = sectionComments.length - 1; i >= 0; i -= 1) {
          // comment not created by signed in user
          if (sectionComments[i].created_by_id !== user.uid && !sectionComments[i].deleted_at) {
            // signed in user is not in comment seen list
            if (!sectionComments[i].seen_list.includes(user.uid)) {
              // add signed in user to the comment seen list
              sectionComments[i].seen_list.push(user.uid);
              // update boolean flag to know we must update
              commentsUpdated = true;
            } else {
              // user is in the seen list, so we do not have to look at older comments
              break;
            }
          }
        }

        if (commentsUpdated) {
          let payload;
          const userModel = {
            id: user.uid,
            avaratSrc: user.photoURL,
            name: user.displayName
          };

          if (isUserExist) {
            payload = {
              [section]: {
                comments: sectionComments
              }
            };
          } else {
            payload = {
              [section]: {
                comments: sectionComments
              },
              users: firebase.firestore.FieldValue.arrayUnion(userModel)
            };
            isUserExist = true;
          }

          comments
            .doc(testletId)
            .update(payload)
            .then(() => true)
            .catch(() => false);
        }
      }
    }
  }

  notifyAdminAboutTestletRevision(testlet, admins) {
    if (isEmailsPaused) {
      return;
    }

    const { testlet_type, created_by, testlet_information, id } = testlet;

    if (testlet_type === testletTypes.COLLABORATION_REVIEW_BY_TESTLET_CREATOR && admins) {
      const title = testlet_information['testlet_title'] || '<No-Title>';
      const testletViewUrl = getBaseUrl() + customTestletViewRoute.to.replace('{testletId}', id);
      const emailSubject = inbdePlatformName + ': A Testlet Originator has revised a testlet for discussion';
      const emailBody =
        'Dear Admin,' +
        '<br><br>The testlet, <em>' +
        title +
        '</em>, has been <strong>' +
        'sent back for discussion' +
        '</strong> by by the testlet originator,' +
        testlet_information['testlet_creator'] +
        ', at ' +
        getDateAndTime(getCurrentTimeStamp()) +
        '.<br>' +
        'Click on the link below to navigate to the testlet: ' +
        '<br>' +
        '<a href=' +
        testletViewUrl +
        '>' +
        testletViewUrl +
        '</a>' +
        '<br><br>' +
        'Thank you,' +
        '<br>' +
        'NBDHE Team';

      this.sendEmail(
        { emailSubject, emailBody },
        admins,
        {
          userTriggerCommunication: created_by,
          mailType: 'notify-admin-for-testlet-discussion',
          testletLink: [testletViewUrl]
        },
        true
      );
    }
  }

  rejectTestletComment(testletId, user, comment) {
    const isComment = comment && comment !== '' ? comment : null;
    const changeCommentModel = getCommentForChange(testletId, { isComment }, user);

    const docRef = comments.doc(testletId);
    database
      .runTransaction(transaction => {
        return transaction.get(docRef).then(() => {
          transaction.set(
            docRef,
            {
              'testlet-changes': {
                comments: firebase.firestore.FieldValue.arrayUnion(changeCommentModel)
              }
            },
            { merge: true }
          );
          return true;
        });
      })
      .then(() => true)
      .catch(() => false);
  }

  sendEmail(email, recipients, metaData, isEmailToAdminsOnly) {
    const sendEmail = functions.httpsCallable('sendEmail');
    try {
      sendEmail({
        email,
        recipients,
        metaData,
        isEmailToAdminsOnly
      });
    } catch (e) {
      console.error(e);
    }
  }

  sendInvitationEmailsToNewUsers(emails, invitedByUser) {
    if (!isIterableArray(emails)) {
      return;
    }

    const request = {
      notificationType: 'invite-new-user',
      signedInUser: invitedByUser,
      additionalData: null,
      recipients: emails,
      isEmailToAdminsOnly: false
    };

    const sendEmailUsingEmails = functions.httpsCallable('sendEmailUsingEmails');
    try {
      sendEmailUsingEmails(request);
    } catch {}
  }

  sendNotificationAndEmail(testletId, usersToNotify, commentDetails) {
    if (isEmailsPaused) {
      return;
    }

    if (isIterableArray(usersToNotify)) {
      const baseUrl = getBaseUrl();
      const testletLink = baseUrl + customTestletEditRoute.to.replace('{testletId}', testletId);
      const { created_by_name, comment, sectionTitle, created_at, created_by_id, formatDate } = commentDetails;

      const emailSubject = inbdePlatformName + ': A Testlet Originator has revised a testlet for discussion';
      const emailBody = `Dear User,<br><br>The following comment has been added to the <strong>${sectionTitle}</strong> by ${created_by_name} at ${formatDate}:<br><br><em>${
        comment.text
      }</em><br><br>Click on the link below to navigate to the testlet: <br><a href=${testletLink}>${testletLink}</a><br><br>Thank you,<br>NBDHE Team`;
      const metadata = {
        sender_id: created_by_id,
        sent_at: created_at,
        mail_type: 'comment-notification',
        links: [testletLink]
      };

      this.sendEmail({ emailBody, emailSubject }, usersToNotify, metadata, false);
    }
  }

  sendAdminEmailForTestletFlagged(testletName, usersToNotify, commentText, sender, action) {
    if (isIterableArray(usersToNotify)) {
      const emailSentAt = getCurrentTimeStamp();
      const hasUserComment = !!commentText;
      const text = hasUserComment
        ? ' The following comment has been added:<br><br><em>' + commentText + '</em><br><br>'
        : '<br><br>';
      const testletLink = getBaseUrl() + customReviewTestletSubRoutes.flagged.to;
      testletName = testletName || '<No-Title>';

      const emailSubject = inbdePlatformName + ': A Testlet has been ' + action;
      const emailBody =
        'Dear Admins,' +
        '<br><br>A testlet for, <em>' +
        testletName +
        '</em>, has been <strong>' +
        action +
        '</strong> by ' +
        sender.displayName +
        ' at ' +
        getDateAndTime(emailSentAt) +
        '.' +
        text +
        'Click on the link below to navigate to the admin dashboard to view the testlet: ' +
        '<br>' +
        '<a href=' +
        testletLink +
        '>' +
        testletLink +
        '</a>' +
        '<br><br>' +
        'Thank you,' +
        '<br>' +
        'NBDHE Team';

      const metaData = {
        sender_id: sender.uid,
        sent_at: emailSentAt,
        mail_type: 'admin-notification',
        links: [testletLink]
      };

      this.sendEmail({ emailBody, emailSubject }, usersToNotify, metaData, true);
    }
  }

  sendAdminEmailForContent(contentLink, contentType, user, inbdeCourse, notificationType) {
    try {
      const contentTypeModel = contentTypes[contentType];
      const request = {
        contentTypeModel,
        notificationType,
        links: [contentLink],
        signedInUser: user,
        additionalData: { inbdeCourse }
      };
      const notifyAdmins = functions.httpsCallable('notifyAdmins');
      notifyAdmins(request);
    } catch (error) {}
  }

  sendAdminEmailForContentSubmission(contentId, contentType, user, inbdeCourse) {
    const contentRouteObject = customContentRoutes[contentType];
    if (!contentRouteObject) {
      return;
    }
    const contentLink = getBaseUrl() + contentRouteObject.editPath.replace('{contentId}', contentId);
    this.sendAdminEmailForContent(contentLink, contentType, user, inbdeCourse, 'content-submission');
  }

  sendAdminEmailForContentFlagged(contentType, user, inbdeCourse) {
    const contentLink = getBaseUrl() + customReviewTestletSubRoutes.flagged.to;
    this.sendAdminEmailForContent(contentLink, contentType, user, inbdeCourse, 'content-flagged');
  }

  sendAdminEmailForTestletSubmission(testletId, inbdeCourse, admins, user) {
    const { displayName, uid } = user;
    const testletLink = getBaseUrl() + customTestletEditRoute.to.replace('{testletId}', testletId);
    const currentTime = getCurrentTimeStamp();
    const emailSubject = inbdePlatformName + ': Testlet Submtted for Review';
    const emailBody = `Dear Admins,<br><br>A testlet for <em>${inbdeCourse}</em> has been submitted by ${displayName} at ${getDateAndTime(
      currentTime
    )}.<br>Click on the link below to navigate to the testlet:<br><a href=${testletLink}>${testletLink}</a><br><br>Thank you,<br>NBDHE Team`;

    const metaData = {
      sender_id: uid,
      sent_at: currentTime,
      mail_type: 'admin-notification',
      action: 'testlet-submission',
      links: [testletLink]
    };
    this.sendEmail({ emailBody, emailSubject }, admins, metaData, true);
  }

  sendContentActionEmail(action, comment, contentId, contentType, recipients, sender) {
    if (!isIterableArray(recipients)) {
      return;
    }

    const contentRouteObject = customContentRoutes[contentType];
    if (!contentRouteObject) {
      return;
    }

    const contentTypeModel = contentTypes[contentType];
    if (!contentTypeModel) {
      return;
    }

    const actionText = Boolean(comment)
      ? ' The following comment has been added:<br><br><em>' + comment + '</em><br><br>'
      : '<br><br>';
    const contentAction = action || 'Updated';
    const contentLink = getBaseUrl() + contentRouteObject.editPath.replace('{contentId}', contentId);
    const emailSentAt = getCurrentTimeStamp();

    const emailSubject = inbdePlatformName + `: Your ${contentTypeModel.name} has been ` + contentAction;
    const emailBody = `Dear User,<br><br>Your ${contentTypeModel.type} has been <strong>${contentAction}</strong> by ${
      sender.displayName
    } at ${getDateAndTime(emailSentAt)}.${actionText}Click on the link below to navigate to the ${
      contentTypeModel.type
    }: <br><a href=${contentLink}>${contentLink}</a><br><br>Thank you,<br>NBDHE Team`;
    const metaData = {
      sender_id: sender.uid,
      sent_at: emailSentAt,
      mail_type: 'faculty-action-notification',
      action: contentAction,
      links: [contentLink]
    };

    this.sendEmail({ emailBody, emailSubject }, recipients, metaData, false);
  }

  sendTestletActionEmail(testletId, usersToNotify, commentText, sender, action) {
    if (isEmailsPaused) {
      return;
    }

    const testletContentType = contentTypes.testlet.type;
    this.sendContentActionEmail(action, commentText, testletId, testletContentType, usersToNotify, sender);
  }

  updateTestletComments(_testletId, _key, _updateValues, _userDetails) {
    return;
    // const updateComments = getCommentsFromUpdates(testletId, key, updateValues, userDetails);

    // try {
    //   const docRef = comments.doc(testletId);
    //   database.runTransaction(async transaction => {
    //     await transaction.get(docRef);
    //     updateComments.forEach(comment => {
    //       transaction.set(
    //         docRef,
    //         {
    //           [key]: {
    //             comments: firebase.firestore.FieldValue.arrayUnion(comment)
    //           }
    //         },
    //         { merge: true }
    //       );
    //     });
    //   });
    // } catch (e) {
    //   console.error(e);
    // }
  }
}

export default CollaborationService;
