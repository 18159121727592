const adminTypes = [
  {
    title: '1',
    color: 'grey',
    class: 'secondary',
    tooltip: 'Testlet has been created and is in progress'
  },
  {
    title: '1A',
    color: 'grey',
    class: 'secondary',
    tooltip: 'Testlet has been created and is in progress'
  },
  {
    title: '1B',
    color: 'grey',
    class: 'secondary',
    tooltip: 'Testlet has been opened for collaboration by faculty and is in progress'
  },
  {
    title: '2A',
    color: 'orange',
    class: 'warning',
    tooltip: 'Testlet is under review by admins'
  },
  {
    title: '2B',
    color: 'orange',
    class: 'warning',
    tooltip: 'Testlet sent back for revision by admin'
  },
  {
    title: '2C',
    color: 'orange',
    class: 'warning',
    tooltip: 'Testlet was opened for collaboration and Originator review is needed'
  },
  {
    title: '3',
    color: 'green',
    class: 'success',
    tooltip: 'Testlet has been approved and is open for collaboration'
  },
  {
    title: '4',
    color: 'blue',
    class: 'primary',
    tooltip: 'Testlet has not been published but is not open for collaboration'
  },
  {
    title: '5',
    color: 'blue',
    class: 'primary',
    tooltip: 'Testlet has been published to students in an NBDHE course'
  }
];

export default adminTypes;
