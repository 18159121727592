import React, { useContext } from 'react';
import { Collapse, Navbar, NavbarToggler, Nav } from 'reactstrap';
import PropTypes from 'prop-types';

import AppContext from '../../context/AppContext';
import { isProd } from '../../helpers/inbdeUtils';
import Logo from './Logo';
import ProfileDropdown from './ProfileDropdown';

const NavbarTop = ({ showLogoOnly }) => {
  const { showBurgerMenu, setShowBurgerMenu } = useContext(AppContext);
  const isProdEnv = isProd();

  return (
    <Navbar light className="fs--1 font-weight-semi-bold row navbar-top sticky-kit" expand>
      {Boolean(showLogoOnly) ? null : (
        <>
          <NavbarToggler onClick={() => setShowBurgerMenu(!showBurgerMenu)} id="burgerMenu" />
          <Logo at="navbar-top" width={200} id="topLogo" />
        </>
      )}
      {isProdEnv ? null : (
        <div className="bg-warning" style={{ borderRadius: '0.5rem' }}>
          <h4 className="mb-0 p-2 d-none d-md-block">QA Environment</h4>
          <h4 className="mb-0 p-2 d-block d-md-none">QA</h4>
        </div>
      )}
      <Collapse navbar>
        <Nav navbar className="align-items-center ml-auto">
          <ProfileDropdown />
        </Nav>
      </Collapse>
    </Navbar>
  );
};

NavbarTop.propTypes = {
  showLogoOnly: PropTypes.bool
};

export default NavbarTop;
