import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

import logo from '../../assets/img/logos/nyu-short-color.png';

const OAuthGoogleLogin = props => (
  <>
    <Button color="outline-nyu" size="sm" block className="mt-2" onClick={props.handleLogin} disabled={props.isLoading}>
      <img className="mr-2 fs-0" src={logo} alt="NYU Dentistry" width={40} /> Login with NYU
    </Button>
  </>
);

OAuthGoogleLogin.propTypes = {
  handleLogin: PropTypes.func,
  isLoading: PropTypes.bool
};

export default OAuthGoogleLogin;
