import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import Flex from '../../common/Flex';
import LoginForm from '../LoginForm';
import withAuthSplit from '../../../hoc/withAuthSplit';

import bgImg from '../../../assets/img/illustrations/nyu-dentistry-building-dh.jpg';

const Login = ({ setBgProps }) => {
  useEffect(() => setBgProps({ image: bgImg, position: '50% 20%' }), [setBgProps]);

  return (
    <Fragment>
      <Flex align="center" justify="center">
        <h3>Login</h3>
      </Flex>
      <LoginForm layout="split" hasLabel />
    </Fragment>
  );
};

Login.propTypes = { setBgProps: PropTypes.func.isRequired };

export default withAuthSplit(Login);
