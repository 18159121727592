import axios from 'axios';

export const getHttpResource = async (url, configs) => {
  const response = await axios.get(url, configs);
  return response;
};

export const postHttpResource = async (url, configs, data) => {
  const response = await axios.post(url, data, configs);
  return response;
};
